@charset "UTF-8";
/*
  Project: The Village of Useful
  Author: Xfive
 */
@font-face {
  font-family: DINNext;
  font-weight: normal;
  font-style: normal;
  src: url("/assets/fonts/DINNextLTPro-Regular.woff") format("woff");
}

@font-face {
  font-family: DINNext;
  font-weight: normal;
  font-style: italic;
  src: url("/assets/fonts/DINNextLTPro-Italic.woff") format("woff");
}

@font-face {
  font-family: DINNext;
  font-weight: bold;
  font-style: normal;
  src: url("/assets/fonts/DINNextLTPro-Bold.woff") format("woff");
}

@font-face {
  font-family: DINNext;
  font-weight: bold;
  font-style: italic;
  src: url("/assets/fonts/DINNextLTPro-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: DINNext;
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/DINNextLTPro-Medium.woff") format("woff");
}

@font-face {
  font-family: DINNext;
  font-weight: 500;
  font-style: italic;
  src: url("/assets/fonts/DINNextLTPro-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'icomoon';
  src: url("/assets/fonts/icomoon.eot?cbhcic");
  src: url("/assets/fonts/icomoon.eot?cbhcic#iefix") format("embedded-opentype"), url("/assets/fonts/icomoon.ttf?cbhcic") format("truetype"), url("/assets/fonts/icomoon.woff?cbhcic") format("woff"), url("/assets/fonts/icomoon.svg?cbhcic#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email:before {
  content: "\e900";
}

.icon-link:before {
  content: "\e901";
}

.icon-linkedin:before {
  content: "\e902";
}

.icon-share:before {
  content: "\e903";
}

.icon-twitter:before {
  content: "\e904";
}

/* 375px */
/* 480px */
/* Max width 479px */
/* 600px */
/* 768px */
/* Max width 767px */
/* 1024px */
/* 1200px */
/* 1600px */
/* 1800px */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/* HTML5 display definitions
       ========================================================================== */
/**
     * Add the correct display in IE <10.
     * Add the correct display in Edge, IE, and Firefox for `details` or `summary`.
     * Add the correct display in IE for `main`.
     */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
     * Add the correct display in IE <10.
     */
audio,
canvas,
progress,
video {
  display: inline-block;
}

/**
     * Add the correct display and remove excess height in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  vertical-align: baseline;
}

/**
     * Add the correct display in IE <11, Safari <8, and Firefox <22.
     * 1. Add the correct display in IE.
     */
template,
[hidden] {
  display: none;
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox <40.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/**
     * Add the correct font style in Android <4.4.
     */
dfn {
  font-style: italic;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 0.75em 0;
}

/**
     * Add the correct background and color in IE <10.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Remove the border on images inside links in IE <11.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1.5em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
       ========================================================================== */
/**
     * Known issues:
     * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
     *   select, unless a border property is set. The default font weight on
     *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
     *   OS X.
     * - It is recommended that you do not style checkbox and radio inputs as
     *   Firefox's implementation does not respect box-sizing, padding, or width.
     * - Certain font size values applied to number inputs cause the cursor style of
     *   the decrement button to change from default to text.
     * - The search input is not fully stylable by default. In Chrome and Safari on
     *   OSX/iOS you can't control font, padding, border, or background. In Chrome
     *   and Safari on Windows you can't control border properly. It will apply
     *   border-width but will only show a border color (which cannot be controlled)
     *   for the outer 1px of that border. Applying -webkit-appearance: textfield
     *   addresses these issues without removing the benefits of search inputs (e.g.
     *   showing past searches). Safari (but not Chrome) will clip the cancel button
     *   on when it has padding (and textfield appearance).
     */
/**
     * 1. Change font properties to `inherit` in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
     * 4. Improve appearance and consistency with IE 6/7.
     */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE <11.
     * 2. Remove the padding in IE <11.
     * 3. Remove excess padding in IE 7.
     *    Known issue: excess padding remains in IE 6.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * Correct the text style of placeholders in Chrome, Edge, and Safari.
     */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     * 4. Correct alignment displayed oddly in IE 6/7.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */
}

/**
     * Restore the font weight unset by a previous rule.
     */
optgroup {
  font-weight: bold;
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #55595c;
  background-color: #fff;
  border-color: #66afe9;
  outline: none;
}

.form-control::placeholder {
  color: #999;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #eceeef;
  opacity: 1;
}

.form-control:disabled {
  cursor: not-allowed;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.5rem - 2px);
}

select.form-control:focus::-ms-value {
  color: #55595c;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}

.col-form-label-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
}

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-static {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-static.form-control-sm, .form-control-static.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: 1.8125rem;
}

.form-control-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: 3.16667rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.75rem;
}

.form-check + .form-check {
  margin-top: -.25rem;
}

.form-check.disabled .form-check-label {
  color: #818a91;
  cursor: not-allowed;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
  cursor: pointer;
}

.form-check-input {
  position: absolute;
  margin-top: .25rem;
  margin-left: -1.25rem;
}

.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  position: relative;
  display: inline-block;
  padding-left: 1.25rem;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
}

.form-check-inline + .form-check-inline {
  margin-left: .75rem;
}

.form-check-inline.disabled {
  color: #818a91;
  cursor: not-allowed;
}

.form-control-feedback {
  margin-top: 0.25rem;
}

.form-control-success,
.form-control-warning,
.form-control-danger {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}

.has-success .form-control-feedback,
.has-success .form-control-label,
.has-success .form-check-label,
.has-success .form-check-inline,
.has-success .custom-control {
  color: #5cb85c;
}

.has-success .form-control {
  border-color: #5cb85c;
}

.has-success .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #a3d7a3;
}

.has-success .input-group-addon {
  color: #5cb85c;
  border-color: #5cb85c;
  background-color: #eaf6ea;
}

.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#5cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}

.has-warning .form-control-feedback,
.has-warning .form-control-label,
.has-warning .form-check-label,
.has-warning .form-check-inline,
.has-warning .custom-control {
  color: #f0ad4e;
}

.has-warning .form-control {
  border-color: #f0ad4e;
}

.has-warning .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f8d9ac;
}

.has-warning .input-group-addon {
  color: #f0ad4e;
  border-color: #f0ad4e;
  background-color: white;
}

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .form-check-label,
.has-danger .form-check-inline,
.has-danger .custom-control {
  color: #d9534f;
}

.has-danger .form-control {
  border-color: #d9534f;
}

.has-danger .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eba5a3;
}

.has-danger .input-group-addon {
  color: #d9534f;
  border-color: #d9534f;
  background-color: #fdf7f7;
}

.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .form-control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

/**
 * Set the global `box-sizing` state to `border-box`.
 *
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */
html {
  box-sizing: border-box;
}

html *,
html *:before,
html *:after {
  box-sizing: inherit;
}

/**
 * As well as using normalize.css, it is often advantageous to remove all
 * margins from certain elements.
 */
body,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
figure,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0;
}

/**
 * Give a help cursor to elements that give extra info on `:hover`.
 */
abbr[title],
dfn[title] {
  cursor: help;
}

/**
 * Remove underlines from potentially troublesome elements.
 */
u,
ins {
  text-decoration: none;
}

/**
 * Apply faux underlines to inserted text via `border-bottom`.
 */
ins {
  border-bottom: 1px solid;
}

/**
 * Where `margin-bottom` is concerned, this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
  margin-bottom: 1.3rem;
}

body {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  body {
    font-size: 1.6rem;
  }
}

@media (min-width: 1700px) {
  body {
    font-size: 1.8rem;
  }
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once, here.
 */
ul, ol, dd {
  margin-left: 1rem;
}

.c-button {
  padding: 14px 24px 13px;
  border-radius: 3px;
  background-color: #0086a1;
  z-index: 126;
  color: #fff;
  font-size: 16px;
  font-variant: small-caps;
  line-height: 1;
  text-transform: lowercase;
  transition: background-color ease .2s;
  text-align: center;
  display: inline-block;
}

@media (min-width: 1700px) {
  .c-button {
    font-size: 17px;
    padding: 16px 30px 15px;
  }
}

.c-button:hover, .c-button:focus {
  background-color: #0091af;
  color: #fff;
}

.c-button:hover .c-button__icon, .c-button:focus .c-button__icon {
  color: #fff;
}

.c-button.is-loading {
  position: relative;
  cursor: progress;
}

.c-button.is-loading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: #0086a1 url("../assets/images/loading.svg") no-repeat 50% 50%;
  background-size: auto 60%;
}

.c-button[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}

.c-button--large {
  font-size: 19px;
  padding: 15px 24px 16px;
}

@media (min-width: 1700px) {
  .c-button--large {
    font-size: 20px;
    padding: 18px 30px 19px;
  }
}

.c-button--small {
  font-size: 17px;
  padding: 11px 16px 12px;
}

@media (min-width: 1024px) {
  .c-button--small {
    font-size: 18px;
    padding: 14px 26px 13px;
  }
}

.c-button__icon {
  color: #fff;
}

.c-button--ghost {
  background-color: transparent;
  border: 1px solid #0086a1;
  color: #0086a1;
}

.c-button--ghost .c-button__icon {
  color: #0086a1;
}

.c-button__icon--arrow:after {
  content: "➔";
  display: inline-block;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  margin-left: 6px;
  margin-top: -2px;
  position: relative;
  vertical-align: middle;
}

button {
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  color: #2c2f2e;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 8px;
}

@media (min-width: 768px) {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 16px;
  }
}

h4, h5, h6 {
  line-height: 1.375;
}

h1, .h1 {
  font-size: 3.4rem;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: -0.01em;
}

@media (min-width: 480px) {
  h1, .h1 {
    font-size: 3.6rem;
  }
}

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 4.2rem;
  }
}

@media (min-width: 1024px) {
  h1, .h1 {
    font-size: 4.8rem;
  }
}

@media (min-width: 1280px) {
  h1, .h1 {
    font-size: 6.4rem;
    line-height: 1;
  }
}

@media (min-width: 1700px) {
  h1, .h1 {
    font-size: 7rem;
    letter-spacing: -0.02em;
  }
}

h2, .h2 {
  font-size: 3rem;
}

@media (min-width: 480px) {
  h2, .h2 {
    font-size: 3.2rem;
  }
}

@media (min-width: 768px) {
  h2, .h2 {
    font-size: 3.8rem;
  }
}

@media (min-width: 1024px) {
  h2, .h2 {
    font-size: 4rem;
  }
}

h3, .h3 {
  font-size: 2.6rem;
}

@media (min-width: 480px) {
  h3, .h3 {
    font-size: 2.8rem;
  }
}

@media (min-width: 768px) {
  h3, .h3 {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  h3, .h3 {
    font-size: 3.2rem;
  }
}

h4, .h4 {
  font-size: 2.2rem;
}

@media (min-width: 480px) {
  h4, .h4 {
    font-size: 2.4rem;
  }
}

@media (min-width: 768px) {
  h4, .h4 {
    font-size: 2.6rem;
  }
}

@media (min-width: 1024px) {
  h4, .h4 {
    font-size: 2.8rem;
  }
}

h5, .h5 {
  font-size: 1.8rem;
  font-weight: 600;
}

@media (min-width: 480px) {
  h5, .h5 {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  h5, .h5 {
    font-size: 2.2rem;
  }
}

@media (min-width: 1024px) {
  h5, .h5 {
    font-size: 2.4rem;
  }
}

h6, .h6 {
  font-size: 1.6rem;
  font-weight: 600;
}

@media (min-width: 480px) {
  h6, .h6 {
    font-size: 1.8rem;
  }
}

@media (min-width: 768px) {
  h6, .h6 {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  h6, .h6 {
    font-size: 2.2rem;
  }
}

hr {
  background-color: #858888;
  border: 0;
  display: block;
  height: 1px;
  margin: 1.3rem auto;
}

a {
  color: #2c2f2e;
  text-decoration: none;
}

a:hover, a:focus {
  color: #2c2f2e;
  outline-color: #40bc99;
}

li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

html {
  background: #fff;
  color: #2c2f2e;
  font-family: "DINNext", arial, sans-serif;
  font-size: 0.625rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  background: #c1e4ea;
}

::selection {
  background: #c1e4ea;
}

.o-container {
  display: block;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1464px;
}

@media (min-width: 480px) {
  .o-container {
    width: 96%;
  }
}

@media (min-width: 768px) {
  .o-container {
    padding-left: 32px;
    padding-right: 32px;
    width: auto;
  }
}

.o-container--expanded {
  max-width: 1724px;
}

.o-copyable:hover, .o-copyable:focus {
  cursor: copy;
}

.o-row {
  min-width: 100%;
}

.o-row::after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 480px) {
  .o-row {
    margin-left: -20px;
  }
}

@media (min-width: 768px) {
  .o-row {
    margin-left: -32px;
  }
}

.o-row--relative {
  position: relative;
}

.o-row--seamless {
  margin: 0;
}

.o-row--seamless [class*=col-] {
  padding: 0;
}

.o-row--flex {
  display: flex;
  flex-flow: row wrap;
}

.o-row--flex::after {
  content: none;
  display: none;
}

.o-row--flex-space-between {
  justify-content: space-between;
}

.o-row--align-center {
  align-items: center;
}

.o-row--align-baseline {
  align-items: baseline;
}

@media (min-width: 1280px) {
  .o-row--gutter-double {
    margin-left: -64px;
  }
}

[class*=col-] {
  width: 100%;
  float: left;
  min-height: 1px;
}

@media (min-width: 480px) {
  [class*=col-] {
    padding-left: 20px;
  }
}

@media (min-width: 768px) {
  [class*=col-] {
    padding-left: 32px;
  }
}

.o-col--auto {
  width: auto;
  flex: 1;
}

.o-col--no-padding-left {
  padding-left: 0;
}

.o-col--no-padding-right {
  padding-right: 0;
}

.o-col-0 {
  float: left;
  width: 0%;
}

.o-col-push-0 {
  margin-left: 0%;
}

.o-col-pull-0 {
  right: auto;
}

.o-col-1 {
  float: left;
  width: 6.25%;
}

.o-col-push-1 {
  margin-left: 6.25%;
}

.o-col-pull-1 {
  right: 6.25%;
}

.o-col-2 {
  float: left;
  width: 12.5%;
}

.o-col-push-2 {
  margin-left: 12.5%;
}

.o-col-pull-2 {
  right: 12.5%;
}

.o-col-3 {
  float: left;
  width: 18.75%;
}

.o-col-push-3 {
  margin-left: 18.75%;
}

.o-col-pull-3 {
  right: 18.75%;
}

.o-col-4 {
  float: left;
  width: 25%;
}

.o-col-push-4 {
  margin-left: 25%;
}

.o-col-pull-4 {
  right: 25%;
}

.o-col-5 {
  float: left;
  width: 31.25%;
}

.o-col-push-5 {
  margin-left: 31.25%;
}

.o-col-pull-5 {
  right: 31.25%;
}

.o-col-6 {
  float: left;
  width: 37.5%;
}

.o-col-push-6 {
  margin-left: 37.5%;
}

.o-col-pull-6 {
  right: 37.5%;
}

.o-col-7 {
  float: left;
  width: 43.75%;
}

.o-col-push-7 {
  margin-left: 43.75%;
}

.o-col-pull-7 {
  right: 43.75%;
}

.o-col-8 {
  float: left;
  width: 50%;
}

.o-col-push-8 {
  margin-left: 50%;
}

.o-col-pull-8 {
  right: 50%;
}

.o-col-9 {
  float: left;
  width: 56.25%;
}

.o-col-push-9 {
  margin-left: 56.25%;
}

.o-col-pull-9 {
  right: 56.25%;
}

.o-col-10 {
  float: left;
  width: 62.5%;
}

.o-col-push-10 {
  margin-left: 62.5%;
}

.o-col-pull-10 {
  right: 62.5%;
}

.o-col-11 {
  float: left;
  width: 68.75%;
}

.o-col-push-11 {
  margin-left: 68.75%;
}

.o-col-pull-11 {
  right: 68.75%;
}

.o-col-12 {
  float: left;
  width: 75%;
}

.o-col-push-12 {
  margin-left: 75%;
}

.o-col-pull-12 {
  right: 75%;
}

.o-col-13 {
  float: left;
  width: 81.25%;
}

.o-col-push-13 {
  margin-left: 81.25%;
}

.o-col-pull-13 {
  right: 81.25%;
}

.o-col-14 {
  float: left;
  width: 87.5%;
}

.o-col-push-14 {
  margin-left: 87.5%;
}

.o-col-pull-14 {
  right: 87.5%;
}

.o-col-15 {
  float: left;
  width: 93.75%;
}

.o-col-push-15 {
  margin-left: 93.75%;
}

.o-col-pull-15 {
  right: 93.75%;
}

.o-col-16 {
  float: left;
  width: 100%;
}

.o-col-push-16 {
  margin-left: 100%;
}

.o-col-pull-16 {
  right: 100%;
}

.o-col-auto {
  width: auto;
}

.o-col-third {
  width: 33.33333%;
}

.o-col-half {
  width: 50%;
}

@media (min-width: 375px) {
  .o-col-xsmall-0 {
    float: left;
    width: 0%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-0 {
    margin-left: 0%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-0 {
    right: auto;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-1 {
    float: left;
    width: 6.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-1 {
    margin-left: 6.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-1 {
    right: 6.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-2 {
    float: left;
    width: 12.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-2 {
    margin-left: 12.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-2 {
    right: 12.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-3 {
    float: left;
    width: 18.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-3 {
    margin-left: 18.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-3 {
    right: 18.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-4 {
    float: left;
    width: 25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-4 {
    margin-left: 25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-4 {
    right: 25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-5 {
    float: left;
    width: 31.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-5 {
    margin-left: 31.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-5 {
    right: 31.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-6 {
    float: left;
    width: 37.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-6 {
    margin-left: 37.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-6 {
    right: 37.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-7 {
    float: left;
    width: 43.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-7 {
    margin-left: 43.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-7 {
    right: 43.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-8 {
    float: left;
    width: 50%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-8 {
    margin-left: 50%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-8 {
    right: 50%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-9 {
    float: left;
    width: 56.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-9 {
    margin-left: 56.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-9 {
    right: 56.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-10 {
    float: left;
    width: 62.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-10 {
    margin-left: 62.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-10 {
    right: 62.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-11 {
    float: left;
    width: 68.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-11 {
    margin-left: 68.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-11 {
    right: 68.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-12 {
    float: left;
    width: 75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-12 {
    margin-left: 75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-12 {
    right: 75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-13 {
    float: left;
    width: 81.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-13 {
    margin-left: 81.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-13 {
    right: 81.25%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-14 {
    float: left;
    width: 87.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-14 {
    margin-left: 87.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-14 {
    right: 87.5%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-15 {
    float: left;
    width: 93.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-15 {
    margin-left: 93.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-15 {
    right: 93.75%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-16 {
    float: left;
    width: 100%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-push-16 {
    margin-left: 100%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-pull-16 {
    right: 100%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-auto {
    width: auto;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-third {
    width: 33.33333%;
  }
}

@media (min-width: 375px) {
  .o-col-xsmall-half {
    width: 50%;
  }
}

@media (min-width: 480px) {
  .o-col-small-0 {
    float: left;
    width: 0%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-0 {
    margin-left: 0%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-0 {
    right: auto;
  }
}

@media (min-width: 480px) {
  .o-col-small-1 {
    float: left;
    width: 6.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-1 {
    margin-left: 6.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-1 {
    right: 6.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-2 {
    float: left;
    width: 12.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-2 {
    margin-left: 12.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-2 {
    right: 12.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-3 {
    float: left;
    width: 18.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-3 {
    margin-left: 18.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-3 {
    right: 18.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-4 {
    float: left;
    width: 25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-4 {
    margin-left: 25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-4 {
    right: 25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-5 {
    float: left;
    width: 31.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-5 {
    margin-left: 31.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-5 {
    right: 31.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-6 {
    float: left;
    width: 37.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-6 {
    margin-left: 37.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-6 {
    right: 37.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-7 {
    float: left;
    width: 43.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-7 {
    margin-left: 43.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-7 {
    right: 43.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-8 {
    float: left;
    width: 50%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-8 {
    margin-left: 50%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-8 {
    right: 50%;
  }
}

@media (min-width: 480px) {
  .o-col-small-9 {
    float: left;
    width: 56.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-9 {
    margin-left: 56.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-9 {
    right: 56.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-10 {
    float: left;
    width: 62.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-10 {
    margin-left: 62.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-10 {
    right: 62.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-11 {
    float: left;
    width: 68.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-11 {
    margin-left: 68.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-11 {
    right: 68.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-12 {
    float: left;
    width: 75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-12 {
    margin-left: 75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-12 {
    right: 75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-13 {
    float: left;
    width: 81.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-13 {
    margin-left: 81.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-13 {
    right: 81.25%;
  }
}

@media (min-width: 480px) {
  .o-col-small-14 {
    float: left;
    width: 87.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-14 {
    margin-left: 87.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-14 {
    right: 87.5%;
  }
}

@media (min-width: 480px) {
  .o-col-small-15 {
    float: left;
    width: 93.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-15 {
    margin-left: 93.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-15 {
    right: 93.75%;
  }
}

@media (min-width: 480px) {
  .o-col-small-16 {
    float: left;
    width: 100%;
  }
}

@media (min-width: 480px) {
  .o-col-small-push-16 {
    margin-left: 100%;
  }
}

@media (min-width: 480px) {
  .o-col-small-pull-16 {
    right: 100%;
  }
}

@media (min-width: 480px) {
  .o-col-small-auto {
    width: auto;
  }
}

@media (min-width: 480px) {
  .o-col-small-third {
    width: 33.33333%;
  }
}

@media (min-width: 480px) {
  .o-col-small-half {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-0 {
    float: left;
    width: 0%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-0 {
    margin-left: 0%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-0 {
    right: auto;
  }
}

@media (min-width: 768px) {
  .o-col-medium-1 {
    float: left;
    width: 6.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-1 {
    margin-left: 6.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-1 {
    right: 6.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-2 {
    float: left;
    width: 12.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-2 {
    margin-left: 12.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-2 {
    right: 12.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-3 {
    float: left;
    width: 18.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-3 {
    margin-left: 18.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-3 {
    right: 18.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-4 {
    float: left;
    width: 25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-4 {
    margin-left: 25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-4 {
    right: 25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-5 {
    float: left;
    width: 31.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-5 {
    margin-left: 31.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-5 {
    right: 31.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-6 {
    float: left;
    width: 37.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-6 {
    margin-left: 37.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-6 {
    right: 37.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-7 {
    float: left;
    width: 43.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-7 {
    margin-left: 43.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-7 {
    right: 43.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-8 {
    float: left;
    width: 50%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-8 {
    margin-left: 50%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-8 {
    right: 50%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-9 {
    float: left;
    width: 56.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-9 {
    margin-left: 56.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-9 {
    right: 56.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-10 {
    float: left;
    width: 62.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-10 {
    margin-left: 62.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-10 {
    right: 62.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-11 {
    float: left;
    width: 68.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-11 {
    margin-left: 68.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-11 {
    right: 68.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-12 {
    float: left;
    width: 75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-12 {
    margin-left: 75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-12 {
    right: 75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-13 {
    float: left;
    width: 81.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-13 {
    margin-left: 81.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-13 {
    right: 81.25%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-14 {
    float: left;
    width: 87.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-14 {
    margin-left: 87.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-14 {
    right: 87.5%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-15 {
    float: left;
    width: 93.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-15 {
    margin-left: 93.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-15 {
    right: 93.75%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-16 {
    float: left;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-push-16 {
    margin-left: 100%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-pull-16 {
    right: 100%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-auto {
    width: auto;
  }
}

@media (min-width: 768px) {
  .o-col-medium-third {
    width: 33.33333%;
  }
}

@media (min-width: 768px) {
  .o-col-medium-half {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-0 {
    float: left;
    width: 0%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-0 {
    right: auto;
  }
}

@media (min-width: 1024px) {
  .o-col-large-1 {
    float: left;
    width: 6.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-1 {
    margin-left: 6.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-1 {
    right: 6.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-2 {
    float: left;
    width: 12.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-2 {
    margin-left: 12.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-2 {
    right: 12.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-3 {
    float: left;
    width: 18.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-3 {
    margin-left: 18.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-3 {
    right: 18.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-4 {
    float: left;
    width: 25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-4 {
    margin-left: 25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-4 {
    right: 25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-5 {
    float: left;
    width: 31.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-5 {
    margin-left: 31.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-5 {
    right: 31.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-6 {
    float: left;
    width: 37.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-6 {
    margin-left: 37.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-6 {
    right: 37.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-7 {
    float: left;
    width: 43.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-7 {
    margin-left: 43.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-7 {
    right: 43.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-8 {
    float: left;
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-8 {
    margin-left: 50%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-8 {
    right: 50%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-9 {
    float: left;
    width: 56.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-9 {
    margin-left: 56.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-9 {
    right: 56.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-10 {
    float: left;
    width: 62.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-10 {
    margin-left: 62.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-10 {
    right: 62.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-11 {
    float: left;
    width: 68.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-11 {
    margin-left: 68.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-11 {
    right: 68.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-12 {
    float: left;
    width: 75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-12 {
    margin-left: 75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-12 {
    right: 75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-13 {
    float: left;
    width: 81.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-13 {
    margin-left: 81.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-13 {
    right: 81.25%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-14 {
    float: left;
    width: 87.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-14 {
    margin-left: 87.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-14 {
    right: 87.5%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-15 {
    float: left;
    width: 93.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-15 {
    margin-left: 93.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-15 {
    right: 93.75%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-16 {
    float: left;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-push-16 {
    margin-left: 100%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-pull-16 {
    right: 100%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-auto {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .o-col-large-third {
    width: 33.33333%;
  }
}

@media (min-width: 1024px) {
  .o-col-large-half {
    width: 50%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-0 {
    float: left;
    width: 0%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-0 {
    right: auto;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-1 {
    float: left;
    width: 6.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-1 {
    margin-left: 6.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-1 {
    right: 6.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-2 {
    float: left;
    width: 12.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-2 {
    margin-left: 12.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-2 {
    right: 12.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-3 {
    float: left;
    width: 18.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-3 {
    margin-left: 18.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-3 {
    right: 18.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-4 {
    float: left;
    width: 25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-4 {
    margin-left: 25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-4 {
    right: 25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-5 {
    float: left;
    width: 31.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-5 {
    margin-left: 31.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-5 {
    right: 31.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-6 {
    float: left;
    width: 37.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-6 {
    margin-left: 37.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-6 {
    right: 37.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-7 {
    float: left;
    width: 43.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-7 {
    margin-left: 43.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-7 {
    right: 43.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-8 {
    float: left;
    width: 50%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-8 {
    margin-left: 50%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-8 {
    right: 50%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-9 {
    float: left;
    width: 56.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-9 {
    margin-left: 56.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-9 {
    right: 56.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-10 {
    float: left;
    width: 62.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-10 {
    margin-left: 62.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-10 {
    right: 62.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-11 {
    float: left;
    width: 68.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-11 {
    margin-left: 68.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-11 {
    right: 68.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-12 {
    float: left;
    width: 75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-12 {
    margin-left: 75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-12 {
    right: 75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-13 {
    float: left;
    width: 81.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-13 {
    margin-left: 81.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-13 {
    right: 81.25%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-14 {
    float: left;
    width: 87.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-14 {
    margin-left: 87.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-14 {
    right: 87.5%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-15 {
    float: left;
    width: 93.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-15 {
    margin-left: 93.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-15 {
    right: 93.75%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-16 {
    float: left;
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-push-16 {
    margin-left: 100%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-pull-16 {
    right: 100%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-auto {
    width: auto;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-third {
    width: 33.33333%;
  }
}

@media (min-width: 1280px) {
  .o-col-large2-half {
    width: 50%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-0 {
    float: left;
    width: 0%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-0 {
    right: auto;
  }
}

@media (min-width: 1700px) {
  .o-col-full-1 {
    float: left;
    width: 6.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-1 {
    margin-left: 6.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-1 {
    right: 6.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-2 {
    float: left;
    width: 12.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-2 {
    margin-left: 12.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-2 {
    right: 12.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-3 {
    float: left;
    width: 18.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-3 {
    margin-left: 18.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-3 {
    right: 18.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-4 {
    float: left;
    width: 25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-4 {
    margin-left: 25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-4 {
    right: 25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-5 {
    float: left;
    width: 31.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-5 {
    margin-left: 31.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-5 {
    right: 31.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-6 {
    float: left;
    width: 37.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-6 {
    margin-left: 37.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-6 {
    right: 37.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-7 {
    float: left;
    width: 43.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-7 {
    margin-left: 43.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-7 {
    right: 43.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-8 {
    float: left;
    width: 50%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-8 {
    margin-left: 50%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-8 {
    right: 50%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-9 {
    float: left;
    width: 56.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-9 {
    margin-left: 56.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-9 {
    right: 56.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-10 {
    float: left;
    width: 62.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-10 {
    margin-left: 62.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-10 {
    right: 62.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-11 {
    float: left;
    width: 68.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-11 {
    margin-left: 68.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-11 {
    right: 68.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-12 {
    float: left;
    width: 75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-12 {
    margin-left: 75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-12 {
    right: 75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-13 {
    float: left;
    width: 81.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-13 {
    margin-left: 81.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-13 {
    right: 81.25%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-14 {
    float: left;
    width: 87.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-14 {
    margin-left: 87.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-14 {
    right: 87.5%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-15 {
    float: left;
    width: 93.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-15 {
    margin-left: 93.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-15 {
    right: 93.75%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-16 {
    float: left;
    width: 100%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-push-16 {
    margin-left: 100%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-pull-16 {
    right: 100%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-auto {
    width: auto;
  }
}

@media (min-width: 1700px) {
  .o-col-full-third {
    width: 33.33333%;
  }
}

@media (min-width: 1700px) {
  .o-col-full-half {
    width: 50%;
  }
}

@media (min-width: 1280px) {
  .o-col-gutter-double {
    padding-left: 64px;
  }
}

@media (min-width: 768px) {
  .o-col-gutter-half {
    padding-left: 16px;
  }
}

.o-header {
  font-size: 0.7rem;
  padding-top: 3.6rem;
}

@media (min-width: 768px) {
  .o-header {
    padding-top: 4.8rem;
    font-size: 1rem;
  }
}

@media (min-width: 1700px) {
  .o-header {
    padding-top: 0;
  }
}

.o-header__heading {
  margin: 0;
}

.o-header__heading-image {
  display: block;
  width: 100%;
  height: auto;
}

.o-header__info {
  font-size: 1.8rem;
  margin: 2.8rem 0 3rem;
  letter-spacing: -0.2px;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .o-header__info {
    font-size: 2rem;
    letter-spacing: -0.1px;
    margin: 2em 0;
  }
}

@media (min-width: 1024px) {
  .o-header__info {
    font-size: 2.2rem;
  }
}

@media (min-width: 1280px) {
  .o-header__info {
    font-size: 2.6rem;
  }
}

@media (min-width: 1700px) {
  .o-header__info {
    font-size: 3rem;
    letter-spacing: -0.2px;
  }
}

.o-header__info--separator {
  margin-bottom: 0;
}

.o-header__info-text > p {
  margin-bottom: 0;
}

.o-header__info-text a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.o-header__info-text a:hover, .o-header__info-text a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.o-list-bare, .c-header-nav__primary,
.c-header-nav__secondary {
  list-style: none;
  margin: 0;
  padding: 0;
}

.o-space-for-header {
  margin-top: 140px;
}

@media (min-width: 375px) {
  .o-space-for-header {
    margin-top: 154px;
  }
}

@media (min-width: 480px) {
  .o-space-for-header {
    margin-top: 166px;
  }
}

@media (min-width: 768px) {
  .o-space-for-header {
    margin-top: 168px;
  }
}

@media (min-width: 1024px) {
  .o-space-for-header {
    margin-top: 184px;
  }
}

@media (min-width: 1700px) {
  .o-space-for-header {
    margin-top: 310px;
  }
}

.c-blog-accessible-pagination {
  display: none;
}

.no-js .c-blog-accessible-pagination,
.template-blog-amp .c-blog-accessible-pagination {
  display: block;
}

.c-blog-accessible-pagination__link {
  margin-right: 20px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-blog-accessible-pagination__link:hover, .c-blog-accessible-pagination__link:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

@media (min-width: 1700px) {
  .c-blog-accessible-pagination__link {
    font-size: 1.8rem;
  }
}

.c-blog-accessible-pagination__link:last-child {
  margin-right: 0;
}

.c-blog-comments {
  padding-top: 32px;
}

@media (min-width: 768px) {
  .c-blog-comments {
    padding-top: 48px;
  }
}

@media (min-width: 1024px) {
  .c-blog-comments {
    padding-top: 72px;
  }
}

@media (min-width: 1280px) {
  .c-blog-comments {
    padding-top: 80px;
  }
}

.c-blog-pagination {
  display: block;
}

.no-js .c-blog-pagination {
  display: none;
}

.c-blog-post-page {
  font-size: 0.6rem;
  position: relative;
}

@media (min-width: 768px) {
  .c-blog-post-page {
    padding-bottom: 3.2rem;
  }
}

@media (min-width: 1024px) {
  .c-blog-post-page {
    font-size: 1rem;
  }
}

.c-blog-post-page__content {
  position: relative;
}

.c-capabilities {
  color: #858888;
  margin-bottom: 24px;
  margin-top: 3px;
}

@media (min-width: 1024px) {
  .c-capabilities {
    margin-bottom: 32px;
    margin-top: 6px;
  }
}

.c-capabilities__capability {
  font-size: 1.6rem;
  font-variant: small-caps;
  text-transform: lowercase;
  color: #858888;
  margin-bottom: 8px;
  line-height: 1;
}

@media (min-width: 480px) {
  .c-capabilities__capability {
    display: block;
  }
}

@media (min-width: 1024px) {
  .c-capabilities__capability {
    font-size: 1.7rem;
  }
}

@media (min-width: 1280px) {
  .c-capabilities__capability {
    font-size: 2rem;
    letter-spacing: -0.2px;
  }
}

@media (min-width: 1700px) {
  .c-capabilities__capability {
    font-size: 2.2rem;
  }
}

.c-capabilities__capability:after {
  display: inline-block;
  content: ", ";
}

@media (min-width: 480px) {
  .c-capabilities__capability:after {
    content: none;
  }
}

.c-capabilities__capability:last-child:after {
  content: '.';
}

@media (min-width: 480px) {
  .c-capabilities__capability:last-child:after {
    content: none;
  }
}

.c-careers-does {
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .c-careers-does {
    margin-bottom: 8rem;
  }
}

.c-careers-does__row {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .c-careers-does__row {
    flex-direction: row;
  }
}

.c-careers-does__column {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .c-careers-does__column {
    width: 50%;
  }
}

.c-careers-does__column-left {
  background-color: #fafec1;
}

.c-careers-does__column-right {
  background-color: #9994f2;
}

.c-careers-does__body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding-right: 16px;
  padding-left: 16px;
  max-width: 732px;
}

@media (min-width: 1024px) {
  .c-careers-does__body {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media (min-width: 1024px) {
  .c-careers-does__column-left > .c-careers-does__body {
    margin-left: auto;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .c-careers-does__column-right > .c-careers-does__body {
    margin-left: 0;
    margin-right: auto;
  }
}

.c-careers-does__header {
  padding-top: 4rem;
  padding-bottom: 10%;
  width: 100%;
}

@media (min-width: 768px) {
  .c-careers-does__header {
    padding-top: 6rem;
  }
}

.c-careers-does__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-careers-does__list,
.c-careers-does__body ul {
  line-height: 1.34;
  list-style: none;
  margin: 0 0 32px;
}

.c-careers-does__body-body {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.c-careers-does__list-element,
.c-careers-does__body ul li {
  margin-bottom: 1em;
}

.c-careers-does__body-list {
  padding-top: 3rem;
  align-self: flex-start;
}

.c-careers-does__column-right .c-careers-does__body-list {
  color: #fff;
}

@media (min-width: 1280px) {
  .c-careers-does__column-right .c-careers-does__body-list {
    order: 2;
  }
}

@media (min-width: 768px) {
  .c-careers-does__column-right .c-careers-does__body-list {
    text-align: right;
  }
}

.c-careers-openings__info {
  margin: 2em 0;
}

.c-careers-openings__info > a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-careers-openings__info > a:hover, .c-careers-openings__info > a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-careers-widget__header {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
  line-height: 0.84;
  margin-bottom: 2rem;
  letter-spacing: -0.2px;
}

@media (min-width: 768px) {
  .c-careers-widget__header {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-careers-widget__header {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .c-careers-widget__header {
    margin-bottom: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-careers-widget__header {
    margin-bottom: 3.4rem;
  }
}

/* stylelint-disable rule-nested-empty-line-before */
.c-careers-widget__offers {
  margin-bottom: 3.2rem;
}

@media (min-width: 1024px) {
  .c-careers-widget__offers {
    margin-bottom: 0;
  }
}

.c-careers-widget__offers .whr-code,
.c-careers-widget__offers .whr-location,
.c-careers-widget__offers .whr-date {
  display: none;
}

.c-careers-widget__offers .whr-dept span {
  display: none;
}

.c-careers-widget__offers ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-careers-widget__offers li {
  margin: 0;
}

.c-careers-widget__offers .whr-item {
  position: relative;
  box-sizing: content-box;
  margin-bottom: 16px;
}

.c-careers-widget__offers .whr-item::after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 1024px) {
  .c-careers-widget__offers .whr-item {
    margin-bottom: 40px;
  }
}

.c-careers-widget__offers .whr-item:last-of-type {
  margin-bottom: 0;
}

.c-careers-widget__offers--has-lines .whr-item {
  border-bottom: 2px solid rgba(45, 48, 47, 0.1);
}

.c-careers-widget__offers--has-lines .whr-item:first-child {
  border-top: 2px solid rgba(45, 48, 47, 0.1);
}

.c-careers-widget__offers .whr-info {
  clear: both;
  display: block;
  width: 100%;
}

.c-careers-widget__offers .whr-title {
  margin: 0;
  font-weight: normal;
  line-height: 1.42;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-careers-widget__offers .whr-title {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-careers-widget__offers .whr-title {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-careers-widget__offers .whr-title {
    font-size: 2.4rem;
  }
}

@media (min-width: 480px) {
  .c-careers-widget__offers .whr-title {
    float: left;
  }
}

@media (min-width: 1024px) {
  .c-careers-widget__offers .whr-title {
    margin-bottom: 18px;
    width: calc(100% - 158px);
  }
}

.c-careers-widget__offers .whr-title a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-careers-widget__offers .whr-title a:hover, .c-careers-widget__offers .whr-title a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-careers-widget__offers .whr-dept {
  background-color: #f1766c;
  font-size: 1.6rem;
  font-variant: small-caps;
  color: #fff;
  text-transform: lowercase;
  display: inline-block;
  padding: 0 20px;
  height: 28px;
  line-height: 28px;
  border-radius: 14px;
  margin-bottom: 26px;
  display: none;
}

@media (min-width: 1024px) {
  .c-careers-widget__offers .whr-dept {
    display: inline-block;
  }
}

@media (min-width: 1700px) {
  .c-careers-widget__offers .whr-dept {
    font-size: 1.8rem;
  }
}

.c-careers-widget__offers .c-button {
  display: none;
  margin-bottom: 18px;
}

@media (min-width: 480px) {
  .c-careers-widget__offers .c-button {
    float: right;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .c-careers-widget__offers .c-button {
    display: inline-block;
  }
}

@keyframes loading {
  0% {
    color: #697170;
  }
  50% {
    color: #858888;
  }
  100% {
    color: #697170;
  }
}

.c-careers-widget__offers .whr-loading {
  animation-name: loading;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

.c-careers-widget__offers .whr-error,
.c-careers-widget__offers .whr-loading {
  color: #697170;
  font-size: 1.4rem;
  line-height: 1.42;
}

/* stylelint-enable rule-nested-empty-line-before */
.c-case-study-teaser {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 6px 16px 3px rgba(44, 47, 46, 0.08);
  display: block;
  margin-bottom: 32px;
  position: relative;
  transition-property: box-shadow;
  transition-duration: 0.15s;
}

@media (min-width: 480px) {
  .c-case-study-teaser {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
}

@media (min-width: 1024px) {
  .c-case-study-teaser {
    box-shadow: 0 16px 29.44px 2.56px rgba(44, 47, 46, 0.1);
    margin-bottom: 48px;
  }
}

.c-case-study-teaser:hover {
  box-shadow: 0 16px 29.44px 2.56px rgba(44, 47, 46, 0.2);
}

.c-case-study-teaser__content {
  background: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 24px;
  font-size: 0.6rem;
  min-height: 1px;
}

@media (min-width: 480px) {
  .c-case-study-teaser__content {
    padding: 16px;
    flex-shrink: 0;
    width: 60%;
  }
}

@media (min-width: 768px) {
  .c-case-study-teaser__content {
    font-size: 0.7rem;
    padding: 24px 28px;
  }
}

@media (min-width: 1024px) {
  .c-case-study-teaser__content {
    padding: 30px 36px;
    font-size: 0.8rem;
  }
}

.c-case-study-teaser__summary {
  margin: 1em 0 0;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.42;
  color: #525857;
}

@media (min-width: 768px) {
  .c-case-study-teaser__summary {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-case-study-teaser__summary {
    font-size: 2rem;
  }
}

.c-case-study-teaser__heading {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
}

@media (min-width: 480px) {
  .c-case-study-teaser__heading {
    font-size: 2.2rem;
  }
}

@media (min-width: 768px) {
  .c-case-study-teaser__heading {
    font-size: 2.8rem;
  }
}

@media (min-width: 1024px) {
  .c-case-study-teaser__heading {
    font-size: 3.2rem;
  }
}

.c-case-study-teaser__bottom {
  margin-top: 1.6rem;
}

.c-case-study-teaser__read-more {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
  white-space: nowrap;
  font-size: 1.8rem;
}

.c-case-study-teaser__read-more:hover, .c-case-study-teaser__read-more:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

@media (min-width: 768px) {
  .c-case-study-teaser__read-more {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-case-study-teaser__read-more {
    font-size: 2.2rem;
  }
}

.c-case-study-teaser__image-link {
  display: none;
}

@media (min-width: 480px) {
  .c-case-study-teaser__image-link {
    bottom: 0;
    display: block;
    margin-right: 32px;
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
  }
}

.c-case-study-teaser__image-link--mobile {
  display: block;
}

@media (min-width: 480px) {
  .c-case-study-teaser__image-link--mobile {
    display: none;
  }
}

.c-case-study-teaser__image {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: block;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (min-width: 480px) {
  .c-case-study-teaser__image {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 1024px) {
  .c-case-study-teaser__bottom {
    align-items: center;
    display: flex;
  }
}

.c-case-study-teaser__key-achievement {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.66;
  color: #6c6e6e;
}

@media (min-width: 768px) {
  .c-case-study-teaser__key-achievement {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .c-case-study-teaser__key-achievement {
    color: #858888;
    line-height: 1.5;
  }
}

.c-category-nav {
  margin-top: 32px;
}

.c-category-nav__option {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 16px;
}

.c-category-nav__link {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #40bc99;
  color: #40bc99;
  display: inline-block;
  font-size: 1.6rem;
  font-variant: small-caps;
  line-height: 1;
  padding: 6px 12px 7px;
  outline: 0;
  text-align: center;
  text-transform: lowercase;
  transition: background-color ease .15s, opacity ease .15s;
}

.c-category-nav__link:hover, .c-category-nav__link:focus {
  color: #40bc99;
  opacity: 0.66;
}

@media (min-width: 1024px) {
  .c-category-nav__link {
    font-size: 1.8rem;
  }
}

@media (min-width: 1700px) {
  .c-category-nav__link {
    font-size: 2rem;
  }
}

.c-category-nav__option--active .c-category-nav__link {
  background-color: #40bc99;
  color: #fff;
}

.c-category-nav__option--active .c-category-nav__link:hover, .c-category-nav__option--active .c-category-nav__link:focus {
  background-color: #52c4a4;
  color: #fff;
  opacity: 1;
}

.c-class-toggler {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
  margin-left: 4px;
}

.c-class-toggler:hover, .c-class-toggler:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-class-toggler__default {
  display: inline;
}

.c-class-toggler__alternative {
  display: none;
}

.c-class-toggler.target-class-off .c-class-toggler__default {
  display: none;
}

.c-class-toggler.target-class-off .c-class-toggler__alternative {
  display: inline;
}

.c-client-logos {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .c-client-logos {
    margin-top: 80px;
  }
}

@media (min-width: 1024px) {
  .c-client-logos {
    margin-top: 80px;
  }
}

@media (min-width: 1280px) {
  .c-client-logos {
    margin-top: 80px;
  }
}

.c-client-logos__client {
  margin-bottom: 28px;
  padding: 0 16px;
  text-align: center;
}

@media (min-width: 480px) {
  .c-client-logos__client {
    margin-bottom: 40px;
    padding: 0 32px;
  }
}

@media (min-width: 768px) {
  .c-client-logos__client {
    padding: 0 40px;
  }
}

@media (min-width: 1280px) {
  .c-client-logos__client {
    margin-bottom: 64px;
  }
}

.c-client-logos__title {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
  letter-spacing: -0.2px;
}

@media (min-width: 768px) {
  .c-client-logos__title {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-client-logos__title {
    font-size: 3rem;
  }
}

@media (min-width: 1280px) {
  .c-client-logos__title {
    margin-bottom: 1.9em;
  }
}

.c-client-logos__image {
  max-height: 64px;
  max-width: 124px;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .c-client-logos__image {
    max-height: 76px;
    max-width: 148px;
  }
}

@media (min-width: 1024px) {
  .c-client-logos__image {
    max-height: 106px;
    max-width: 206px;
  }
}

@media (min-width: 1700px) {
  .c-client-logos__image {
    max-height: 130px;
    max-width: 254px;
  }
}

.c-contact-body__title {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .c-contact-body__title {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-contact-body__title {
    font-size: 3rem;
  }
}

@media (min-width: 1700px) {
  .c-contact-body__title {
    margin-bottom: 1.9em;
  }
}

.c-contact-body__text > p {
  color: #525857;
  margin: 0 0 1.5rem;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-contact-body__text > p {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-contact-body__text > p {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-contact-body__text > p {
    font-size: 2.4rem;
  }
}

.c-contact-body__text a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-contact-body__text a:hover, .c-contact-body__text a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-contact-form .parsley-errors-list {
  margin: -20px 0 16px;
  list-style: none;
  color: #f1766c;
  font-size: 1.6rem;
}

@media (min-width: 1024px) {
  .c-contact-form .parsley-errors-list {
    margin: -40px 0 35px;
  }
}

.c-contact-form .parsley-errors-list li {
  padding-left: 0;
}

.c-contact-form__label {
  display: block;
  margin-bottom: 0.4em;
  font-size: 1.5rem;
  font-variant: small-caps;
  text-transform: lowercase;
  cursor: pointer;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .c-contact-form__label {
    font-size: 1.6rem;
  }
}

@media (min-width: 1024px) {
  .c-contact-form__label {
    font-size: 1.8rem;
  }
}

.c-contact-form__label--checkbox {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.c-contact-form__check-label {
  display: inline-block;
  font-size: 1.6rem;
  vertical-align: middle;
}

@media (min-width: 1024px) {
  .c-contact-form__check-label {
    font-size: 1.8rem;
  }
}

.c-contact-form__fieldset {
  border-width: 0;
  margin-bottom: 0;
  min-width: 0;
  max-width: 100%;
  width: 100%;
}

.c-contact-form__input,
.c-contact-form__textarea {
  transition: border-color 0.2s;
  border: 1px solid #858888;
  border-radius: 3px;
  padding: 1rem 1.2rem 0.9rem;
  margin-bottom: 24px;
  width: 100%;
  background: #fff;
  font-size: 1.8rem;
  color: #2c2f2e;
}

@media (min-width: 1024px) {
  .c-contact-form__input,
  .c-contact-form__textarea {
    margin-bottom: 44px;
  }
}

@media (min-width: 1700px) {
  .c-contact-form__input,
  .c-contact-form__textarea {
    font-size: 2.4rem;
    padding: 0.8rem 1.6rem 0.7rem;
  }
}

.c-contact-form__input::-webkit-input-placeholder,
.c-contact-form__textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #bbb;
}

.c-contact-form__input::-moz-placeholder,
.c-contact-form__textarea::-moz-placeholder {
  opacity: 1;
  color: #bbb;
}

.c-contact-form__input:-moz-placeholder,
.c-contact-form__textarea:-moz-placeholder {
  opacity: 1;
  color: #bbb;
}

.c-contact-form__input:-ms-input-placeholder,
.c-contact-form__textarea:-ms-input-placeholder {
  opacity: 1;
  color: #bbb;
}

.c-contact-form__input:focus,
.c-contact-form__textarea:focus {
  outline: 0 none;
  border-color: #2c2f2e;
}

.c-contact-form__textarea {
  height: 160px;
  resize: vertical;
}

.c-contact-form__item {
  margin-bottom: 28px;
}

@media (min-width: 1024px) {
  .c-contact-form__item {
    margin-bottom: 44px;
  }
}

.c-contact-form-intro {
  margin-top: 2.4rem;
}

@media (min-width: 768px) {
  .c-contact-form-intro {
    margin-top: 5.6rem;
  }
}

.c-contact-form-intro h2 {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .c-contact-form-intro h2 {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-contact-form-intro h2 {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .c-contact-form-intro h2 {
    margin-bottom: 2.6rem;
  }
}

.c-contact-form-intro p {
  color: #525857;
  margin: 0 0 24px;
  font-weight: normal;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-contact-form-intro p {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-contact-form-intro p {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-contact-form-intro p {
    font-size: 2.4rem;
  }
}

@media (min-width: 1024px) {
  .c-contact-form-intro p {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .c-contact-form-intro p:last-of-type {
    margin-bottom: 0;
  }
}

.c-contact-form-intro__separator {
  border-bottom: 2px solid #e4e7e7;
  padding-bottom: 0;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .c-contact-form-intro__separator {
    padding-bottom: 8rem;
    margin-bottom: 8rem;
  }
}

.c-contact-form__message {
  color: #525857;
  margin: 36px 0 24px 0;
  font-weight: normal;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-contact-form__message {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-contact-form__message {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-contact-form__message {
    font-size: 2.4rem;
  }
}

@media (min-width: 1024px) {
  .c-contact-form__message {
    margin-bottom: 40px;
  }
}

.c-errors__error {
  color: #da3f3f;
}

.c-contact-page {
  font-size: 0.6rem;
}

@media (min-width: 1024px) {
  .c-contact-page {
    font-size: 1rem;
  }
}

.c-content-strip {
  margin-top: 2.4rem;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

.c-content-strip a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-content-strip a:hover, .c-content-strip a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

@media (min-width: 768px) {
  .c-content-strip {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-content-strip {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-content-strip {
    font-size: 2.4rem;
  }
}

@media (min-width: 768px) {
  .c-content-strip {
    margin-top: 5.6rem;
  }
}

.c-content-strip:first-child {
  margin-top: 0;
}

.c-content-strip h2 {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .c-content-strip h2 {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-content-strip h2 {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .c-content-strip h2 {
    margin-bottom: 2.6rem;
  }
}

.c-content-strip a h2 {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-content-strip a h2:hover, .c-content-strip a h2:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-content-strip p {
  color: #525857;
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  .c-content-strip p {
    margin-bottom: 40px;
  }
}

.c-content-strip p:last-of-type {
  margin-bottom: 0;
}

.c-content-strip ul, .c-content-strip ol {
  margin: 24px 0 24px 15px;
  position: relative;
}

@media (min-width: 1024px) {
  .c-content-strip ul, .c-content-strip ol {
    margin: 40px 0 40px 20px;
  }
}

.c-content-strip ul li, .c-content-strip ol li {
  color: #525857;
  margin-bottom: 16px;
  padding-left: 8px;
}

.c-custom-checkbox {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  min-width: 25px;
  border: 1px solid #c1e4ea;
  margin-right: 15px;
  vertical-align: middle;
}

.c-custom-checkbox__input {
  appearance: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.c-custom-checkbox__input:checked ~ .c-custom-checkbox__check {
  background: #c1e4ea;
}

.c-custom-checkbox__input:focus ~ .c-custom-checkbox__check {
  box-shadow: 0 0 3px #c1e4ea inset;
  outline: 1px solid #88cbd7;
}

.c-custom-checkbox__check {
  transition: background-color 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.c-custom-checkbox__check::before {
  content: '';
  transform: rotate(-45deg);
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.c-custom-select {
  background: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: none !important;
  overflow: hidden;
  position: relative;
}

.c-custom-select__input {
  background: transparent !important;
  border-radius: 4px;
  border: 1px solid #858888;
  display: block;
  font-size: 1.8rem;
  font-weight: normal;
  padding: 1rem 68px 0.9rem 1.2rem;
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: '';
  text-indent: 0.01px;
  transition: border-color 0.2s;
  width: 100%;
  z-index: 2;
}

@media (min-width: 1700px) {
  .c-custom-select__input {
    font-size: 2.4rem;
    padding: 0.8rem 68px 0.7rem 1.6rem;
  }
}

.c-custom-select__input::-ms-expand {
  display: none;
}

.c-custom-select__input:focus {
  outline: 0 none;
  border-color: #2c2f2e;
}

.c-custom-select__input option {
  color: #2c2f2e;
}

.c-custom-select__icon {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 0;
  width: 20px;
  height: 20px;
}

.c-custom-select__icon:before {
  font-size: 20px;
  color: #2c2f2e;
  content: "▾";
  line-height: 1;
}

.c-website-footer {
  position: relative;
  background-color: #fff;
}

.c-website-footer::after {
  content: "";
  display: table;
  clear: both;
}

.c-website-footer__link {
  text-decoration: none;
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
  color: #2c2f2e;
  white-space: nowrap;
  font-size: 1.8rem;
}

.c-website-footer__link:hover, .c-website-footer__link:focus {
  box-shadow: inset 0 -0.34em 0 #c1e4ea;
}

.c-website-footer__terms,
.c-website-footer__map {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
  color: #2c2f2e;
  white-space: nowrap;
}

.c-website-footer__terms:hover, .c-website-footer__terms:focus,
.c-website-footer__map:hover,
.c-website-footer__map:focus {
  box-shadow: inset 0 -0.35em 0 #c1e4ea;
}

.c-website-footer__container {
  padding: 48px 0;
}

.c-website-footer__container::after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 1280px) {
  .c-website-footer__container {
    padding: 60px 0 72px;
  }
}

@media (min-width: 1700px) {
  .c-website-footer__container {
    padding: 80px 0;
  }
}

.c-website-footer__header-name {
  margin: 0 0 3px;
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: -0.2px;
}

@media (min-width: 1700px) {
  .c-website-footer__header-name {
    font-size: 3.6rem;
  }
}

.c-website-footer__header-desc {
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;
  color: #858888;
}

@media (min-width: 768px) {
  .c-website-footer__header-desc {
    font-size: 1.6rem;
  }
}

@media (min-width: 1700px) {
  .c-website-footer__header-desc {
    font-size: 1.8rem;
  }
}

.c-website-footer__info {
  margin-top: 50px;
  line-height: 1.38;
  font-size: 1.3rem;
  display: flex;
}

@media (min-width: 480px) {
  .c-website-footer__info {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .c-website-footer__info {
    line-height: normal;
  }
}

@media (min-width: 1280px) {
  .c-website-footer__info {
    font-size: 1.6rem;
  }
}

@media (min-width: 1700px) {
  .c-website-footer__info {
    font-size: 1.8rem;
  }
}

.c-website-footer__info-logo-image {
  width: 100%;
}

.c-website-footer__info-address {
  color: #525857;
  align-self: flex-end;
  white-space: nowrap;
  line-height: 1.5;
}

.c-website-footer__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-website-footer__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;
}

@media (min-width: 768px) {
  .c-website-footer__right {
    margin-top: 0;
  }
}

.c-website-footer__links-col {
  list-style: none;
  margin: 0;
}

.c-website-footer__linkcontainer {
  line-height: 1.8;
}

.c-website-footer__copy-social {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .c-website-footer__copy-social {
    margin-top: 32px;
  }
}

.c-website-footer__copy {
  color: #525857;
  font-size: 1.3rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .c-website-footer__copy {
    font-size: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .c-website-footer__copy {
    font-size: 1.6rem;
  }
}

@media (min-width: 1700px) {
  .c-website-footer__copy {
    font-size: 1.8rem;
  }
}

.c-website-footer__social {
  text-align: center;
  margin-top: 35px;
}

@media (min-width: 768px) {
  .c-website-footer__social {
    margin-top: 0;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .c-website-footer__social {
    text-align: right;
  }
}

.c-website-footer__social-link {
  margin-left: 10px;
  margin-right: 10px;
  transition: color .2s ease;
}

@media (min-width: 768px) {
  .c-website-footer__social-link {
    margin-left: 20px;
    margin-right: 0;
  }
  .c-website-footer__social-link:first-child {
    margin-left: 0;
  }
}

.c-website-footer__social-link:hover, .c-website-footer__social-link:focus {
  color: #4a4e4d;
}

.c-website-footer__social-icon {
  font-size: 24px;
  width: 24px;
  height: 20px;
}

@media (min-width: 1700px) {
  .c-website-footer__social-icon {
    font-size: 30px;
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 480px) {
  .c-form {
    padding: 24px;
    background: #C2E4E8;
    color: #000;
  }
}

@media (min-width: 768px) {
  .c-form {
    padding: 32px;
  }
}

.c-header-menu {
  display: flex;
  align-items: flex-start;
  margin-top: 2.2rem;
}

@media (min-width: 768px) {
  .c-header-menu {
    margin-top: 5.4rem;
  }
}

@media (min-width: 1024px) {
  .c-header-menu {
    margin-top: 5.8rem;
  }
}

@media (min-width: 1700px) {
  .c-header-menu {
    margin-top: 11.8rem;
  }
}

.c-header-menu.is-sticked {
  transform: translateY(-22px);
}

@media (min-width: 768px) {
  .c-header-menu.is-sticked {
    transform: translateY(-54px);
  }
}

@media (min-width: 1024px) {
  .c-header-menu.is-sticked {
    transform: translateY(-44px);
  }
}

@media (min-width: 1700px) {
  .c-header-menu.is-sticked {
    transform: translateY(-104px);
  }
}

.c-header-nav {
  display: none;
  opacity: 1;
}

@media (min-width: 1024px) {
  .c-header-nav {
    display: block;
    margin-top: 64px;
    margin-left: auto;
    margin-right: 6.25%;
  }
}

@media (min-width: 1700px) {
  .c-header-nav {
    margin-top: 128px;
  }
}

.c-header-nav.is-sticked {
  transform: translateY(-44px);
}

@media (min-width: 1700px) {
  .c-header-nav.is-sticked {
    transform: translateY(-108px);
  }
}

.c-header-nav__primary,
.c-header-nav__secondary {
  display: flex;
  justify-content: flex-end;
}

.c-header-nav__secondary {
  margin-bottom: 16px;
}

.c-header-nav__item {
  margin-right: 32px;
}

@media (min-width: 1280px) {
  .c-header-nav__item {
    margin-right: 40px;
  }
}

@media (min-width: 1024px) {
  .c-header-nav__item:last-child {
    margin-right: 0;
  }
}

.c-header-nav__link {
  font-size: 2rem;
  line-height: 1.42;
  margin-top: 30px;
  box-shadow: inset 0 -2px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-header-nav__link:hover, .c-header-nav__link:focus {
  box-shadow: inset 0 -7px 0 #c1e4ea;
}

@media (min-width: 1024px) {
  .c-header-nav__link {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-header-nav__link {
    font-size: 2.6rem;
  }
}

.is-current .c-header-nav__link {
  box-shadow: inset 0 -7px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.is-current .c-header-nav__link:hover, .is-current .c-header-nav__link:focus {
  box-shadow: inset 0 -7px 0 #c1e4ea;
}

.c-header-separator {
  margin-bottom: 24px;
  margin-top: 24px;
}

@media (min-width: 768px) {
  .c-header-separator {
    margin-top: 32px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .c-header-separator {
    margin-top: 48px;
    margin-bottom: 56px;
  }
}

@media (min-width: 1280px) {
  .c-header-separator {
    margin-top: 72px;
    margin-bottom: 80px;
  }
}

.c-home-careers {
  font-size: 0.7rem;
}

@media (min-width: 1024px) {
  .c-home-careers {
    font-size: 1rem;
  }
}

.c-home-careers__header {
  width: 60%;
  max-width: 360px;
}

@media (min-width: 1024px) {
  .c-home-careers__header {
    max-width: 540px;
    width: 50%;
    margin-bottom: 6rem;
  }
}

.c-home-careers__header-image {
  width: 100%;
}

.c-home-careers__info h2,
.c-home-careers__info-header {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
  margin-bottom: 1.4rem;
}

@media (min-width: 768px) {
  .c-home-careers__info h2,
  .c-home-careers__info-header {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-home-careers__info h2,
  .c-home-careers__info-header {
    font-size: 3rem;
  }
}

@media (min-width: 768px) {
  .c-home-careers__info h2,
  .c-home-careers__info-header {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .c-home-careers__info h2,
  .c-home-careers__info-header {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-home-careers__info h2,
  .c-home-careers__info-header {
    margin-bottom: 3rem;
  }
}

.c-home-careers__info p,
.c-home-careers__info-body {
  font-size: 1.5rem;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
  margin-bottom: 1.6rem;
}

@media (min-width: 768px) {
  .c-home-careers__info p,
  .c-home-careers__info-body {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-home-careers__info p,
  .c-home-careers__info-body {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-home-careers__info p,
  .c-home-careers__info-body {
    font-size: 2.4rem;
  }
}

@media (min-width: 768px) {
  .c-home-careers__info p,
  .c-home-careers__info-body {
    margin-bottom: 2.2rem;
  }
}

@media (min-width: 1280px) {
  .c-home-careers__info p,
  .c-home-careers__info-body {
    margin-bottom: 3.2rem;
  }
}

.c-home-careers__info p > a,
.c-home-careers__info-body > a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-home-careers__info p > a:hover, .c-home-careers__info p > a:focus,
.c-home-careers__info-body > a:hover,
.c-home-careers__info-body > a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-home-intro {
  padding-top: 3.6rem;
}

@media (min-width: 768px) {
  .c-home-intro {
    padding-top: 4.8rem;
  }
}

@media (min-width: 1700px) {
  .c-home-intro {
    padding-top: 0;
  }
}

.c-home-intro__copy {
  font-size: 1.8rem;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-home-intro__copy {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-home-intro__copy {
    font-size: 2.2rem;
  }
}

@media (min-width: 1280px) {
  .c-home-intro__copy {
    font-size: 2.6rem;
  }
}

@media (min-width: 1700px) {
  .c-home-intro__copy {
    font-size: 3rem;
    letter-spacing: -0.2px;
  }
}

.c-home-intro__copy a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-home-intro__copy a:hover, .c-home-intro__copy a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-home-intro__beard-img {
  width: 90px;
}

@media (min-width: 375px) {
  .c-home-intro__beard-img {
    width: 110px;
  }
}

@media (min-width: 1700px) {
  .c-home-intro__beard-img {
    width: 132px;
  }
}

.c-container {
  max-width: 900px;
}

.c-main-nav {
  margin-top: 160px;
}

@media (min-width: 375px) {
  .c-main-nav {
    margin-top: 180px;
  }
}

@media (min-width: 1024px) {
  .c-main-nav {
    margin-top: 240px;
  }
}

.c-main-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 3.2rem;
  line-height: 1.6;
}

@media (min-width: 480px) {
  .c-main-nav__list {
    font-size: 3.6rem;
  }
}

@media (min-width: 768px) {
  .c-main-nav__list {
    line-height: 1.43;
    font-size: 4.8rem;
  }
}

@media (min-width: 1024px) {
  .c-main-nav__list {
    font-size: 6rem;
  }
}

@media (min-width: 1280px) {
  .c-main-nav__list {
    font-size: 7rem;
  }
}

.c-main-nav__item {
  margin: 0;
}

.c-main-nav__link {
  box-shadow: inset 0 -2px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
  letter-spacing: -0.5px;
}

.c-main-nav__link:hover, .c-main-nav__link:focus {
  box-shadow: inset 0 -7px 0 #c1e4ea;
}

@media (min-width: 1700px) {
  .c-main-nav__link {
    box-shadow: inset 0 -3px 0 #c1e4ea;
    color: #2c2f2e;
    padding-bottom: 2px;
    text-decoration: none;
    transition: 0.2s box-shadow ease;
    letter-spacing: -1.5px;
  }
  .c-main-nav__link:hover, .c-main-nav__link:focus {
    box-shadow: inset 0 -10px 0 #c1e4ea;
  }
}

.c-main-nav__link--on-layer {
  color: #fff !important;
  box-shadow: inset 0 -2px 0 #fff;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-main-nav__link--on-layer:hover, .c-main-nav__link--on-layer:focus {
  box-shadow: inset 0 -7px 0 #fff;
}

.c-main-nav__small-list {
  list-style: none;
  margin: 0;
  font-size: 2.4rem;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
}

@media (min-width: 480px) {
  .c-main-nav__small-list {
    font-size: 2.8rem;
  }
}

@media (min-width: 768px) {
  .c-main-nav__small-list {
    font-size: 3.2rem;
    margin-top: 60px;
  }
}

.c-main-nav__small-item {
  margin: 0 3.2rem 0 0;
}

@media (min-width: 480px) {
  .c-main-nav__small-item {
    margin-right: 4rem;
  }
}

@media (min-width: 768px) {
  .c-main-nav__small-item {
    margin-right: 4.8rem;
  }
}

.c-main-nav__small-link {
  color: #fff !important;
  box-shadow: inset 0 -2px 0 #fff;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-main-nav__small-link:hover, .c-main-nav__small-link:focus {
  box-shadow: inset 0 -5px 0 #fff;
}

@media (min-width: 1700px) {
  .c-main-nav__small-link:not(:hover):not(:focus) {
    box-shadow: inset 0 -3px 0 #fff;
  }
  .c-main-nav__small-link:hover, .c-main-nav__small-link:focus {
    box-shadow: inset 0 -7px 0 #fff;
  }
}

.c-media-outlet {
  margin-top: 24px;
}

.c-media-outlet__image {
  max-height: 60px;
  max-width: 220px;
}

.c-menu-layer {
  position: fixed;
  top: -9999em;
  left: -9999em;
  width: 100%;
  height: 100%;
  background-color: #40bc99;
  z-index: 500;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.c-menu-layer.has-scroll {
  overflow-y: scroll;
}

.c-menu-trigger {
  background-color: #40bc99;
  height: 40px;
  border-radius: 4rem;
  text-transform: lowercase;
  font-variant: small-caps;
  font-size: 1.6rem;
  outline: 0;
  transition: background-color ease 200ms, width ease 200ms;
  width: 108px;
  flex: auto 0 0;
}

.c-menu-trigger:hover, .c-menu-trigger:focus {
  background-color: #52c4a4;
}

@media (min-width: 1024px) {
  .c-menu-trigger {
    display: none;
  }
}

@media (min-width: 1700px) {
  .c-menu-trigger {
    width: 5.4rem;
    width: 148px;
    height: 5.4rem;
    font-size: 2rem;
  }
}

.c-menu-trigger--on-layer {
  background-color: #fff;
}

.c-menu-trigger--on-layer:hover, .c-menu-trigger--on-layer:focus {
  background-color: #fff;
}

.c-menu-trigger__text {
  display: inline-block;
  line-height: 2rem;
  vertical-align: middle;
  color: #fff;
  transition: width ease 200ms, opacity ease 200ms;
}

.c-menu-trigger__text--on-layer {
  color: #40bc99;
}

.c-menu-trigger:hover .c-menu-trigger__text--on-layer,
.c-menu-trigger:focus .c-menu-trigger__text--on-layer {
  color: #369f82;
}

.c-menu-trigger__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1.5rem;
  width: 18px;
  height: 18px;
  border-right: 0 none;
  transition: all ease .35s;
}

.is-open .c-menu-trigger__icon {
  transform: scale(0.778);
}

.c-menu-trigger__icon_stroke {
  background: #fff;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  transition: height ease .35s, transform ease .35s;
}

.is-open .c-menu-trigger__icon_stroke {
  height: 3px;
}

.c-menu-trigger__icon_stroke--1 {
  top: 0;
}

.is-open .c-menu-trigger__icon_stroke--1 {
  transform: translateY(7px) scale(0);
}

.c-menu-trigger__icon_stroke--2 {
  top: 7px;
}

.is-open .c-menu-trigger__icon_stroke--2 {
  transform: rotateZ(45deg);
}

.c-menu-trigger__icon_stroke--3 {
  top: 7px;
}

.is-open .c-menu-trigger__icon_stroke--3 {
  transform: rotateZ(-45deg);
}

.c-menu-trigger__icon_stroke--4 {
  top: 14px;
}

.is-open .c-menu-trigger__icon_stroke--4 {
  transform: translateY(-7px) scale(0);
}

.c-menu-trigger__icon--on-layer .c-menu-trigger__icon_stroke {
  background: #40bc99;
}

.c-menu-trigger:hover .c-menu-trigger__icon--on-layer .c-menu-trigger__icon_stroke,
.c-menu-trigger:focus .c-menu-trigger__icon--on-layer .c-menu-trigger__icon_stroke {
  background: #369f82;
}

.c-move-the-needle {
  overflow: hidden;
  margin-bottom: 88px;
  margin-top: 44px;
}

@media (min-width: 768px) {
  .c-move-the-needle {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media (min-width: 1280px) {
  .c-move-the-needle {
    margin-bottom: 150px;
  }
}

@media (min-width: 768px) {
  .c-move-the-needle--inline {
    margin-top: 72px;
    margin-bottom: 64px;
  }
}

.c-move-the-needle__arrow {
  position: relative;
  top: 1vw;
  margin: 0 auto;
  max-width: 260px;
  z-index: 1;
}

@media (min-width: 480px) {
  .c-move-the-needle__arrow {
    margin-bottom: -24px;
    transform: rotateZ(-15deg);
  }
}

@media (min-width: 600px) {
  .c-move-the-needle__arrow {
    margin-bottom: 8px;
    max-width: none;
    transform: rotateZ(15deg);
  }
}

@media (min-width: 1024px) {
  .c-move-the-needle__arrow {
    transform: rotateZ(10deg);
  }
}

.c-move-the-needle__arrow--mobile {
  display: block;
}

@media (min-width: 600px) {
  .c-move-the-needle__arrow--mobile {
    display: none;
  }
}

.c-move-the-needle__arrow--desktop {
  display: none;
}

@media (min-width: 600px) {
  .c-move-the-needle__arrow--desktop {
    display: block;
  }
}

.c-move-the-needle__bg {
  bottom: 0;
  left: -48px;
  height: 100%;
  max-width: none;
  position: absolute;
  right: -48px;
  top: 0;
  width: calc(100% + 96px);
  z-index: -1;
}

@media (min-width: 480px) {
  .c-move-the-needle__bg {
    left: 0;
    right: 0;
    width: 100%;
  }
}

.c-move-the-needle__copy {
  padding: 40px 24px 40px;
  position: relative;
  margin-top: -16px;
  z-index: 0;
}

@media (min-width: 480px) {
  .c-move-the-needle__copy {
    padding: 48px 32px;
  }
}

.c-move-the-needle__text {
  text-align: left;
}

@media (min-width: 768px) {
  .c-move-the-needle__text {
    text-align: left;
  }
}

.c-move-the-needle__text > p {
  font-size: 1.8rem;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-move-the-needle__text > p {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-move-the-needle__text > p {
    font-size: 2rem;
  }
}

@media (min-width: 1700px) {
  .c-move-the-needle__text > p {
    font-size: 2.4rem;
  }
}

.c-move-the-needle__text > p:last-child {
  margin-bottom: 0;
}

.c-move-the-needle__text a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-move-the-needle__text a:hover, .c-move-the-needle__text a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-move-the-needle__cta {
  margin: -24px 24px 0 24px;
  text-align: right;
  position: relative;
  z-index: 2;
}

@media (min-width: 480px) {
  .c-move-the-needle__cta {
    margin: -24px 48px 0;
  }
}

@media (min-width: 768px) {
  .c-move-the-needle__cta {
    text-align: right;
    margin: -24px 56px 0;
  }
}

.c-move-the-needle__cta .c-button {
  text-align: center;
  width: 100%;
}

@media (min-width: 480px) {
  .c-move-the-needle__cta .c-button {
    width: auto;
  }
}

.c-newsletter-message-box {
  color: #fff;
  font-size: 1.2rem;
  margin: 8px 0;
  height: 30px;
}

@media (min-width: 1024px) {
  .c-newsletter-message-box {
    font-size: 1.4rem;
    position: absolute;
    top: calc(100% - 21px);
    margin: auto;
  }
}

.c-newsletter {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.c-newsletter::before {
  content: '';
  position: absolute;
  right: -15px;
  bottom: 0;
  left: -15px;
  height: 300px;
  background: url("../assets/images/footer/wave.svg") 50% 0;
  background-size: cover;
}

@media (min-width: 768px) {
  .c-newsletter::before {
    height: 160px;
  }
}

@media (min-width: 1700px) {
  .c-newsletter::before {
    height: 200px;
  }
}

.c-newsletter .parsley-errors-list {
  position: absolute;
  margin: 3px 0;
  list-style: none;
  color: #4a4e4d;
  text-align: right;
  font-size: 1.2rem;
  width: 100%;
}

@media (min-width: 480px) {
  .c-newsletter .parsley-errors-list {
    width: calc(100% - 30px);
  }
}

@media (min-width: 768px) {
  .c-newsletter .parsley-errors-list {
    margin: 8px 0;
    font-size: 1.6rem;
    width: auto;
  }
}

.c-newsletter__container {
  position: relative;
  height: 100%;
}

.c-newsletter__backgroundimage {
  position: absolute;
  right: -90px;
  width: 270px;
  bottom: 220px;
}

@media (min-width: 480px) {
  .c-newsletter__backgroundimage {
    right: -100px;
    width: 350px;
    bottom: 205px;
  }
}

@media (min-width: 768px) {
  .c-newsletter__backgroundimage {
    right: 0;
    bottom: 75px;
    width: 380px;
  }
}

@media (min-width: 1280px) {
  .c-newsletter__backgroundimage {
    width: 480px;
    bottom: 55px;
  }
}

@media (min-width: 1700px) {
  .c-newsletter__backgroundimage {
    right: auto;
    left: 50%;
    margin-left: 270px;
    width: 640px;
    bottom: 40px;
  }
}

.c-newsletter__header {
  position: relative;
  width: 100%;
  max-width: 600px;
}

@media (min-width: 768px) {
  .c-newsletter__header {
    max-width: 450px;
  }
}

@media (min-width: 1024px) {
  .c-newsletter__header {
    width: 60%;
    max-width: 600px;
  }
}

@media (min-width: 1280px) {
  .c-newsletter__header {
    max-width: none;
    margin-bottom: 42px;
    width: 68.75%;
  }
}

.c-newsletter__header-image {
  width: 100%;
}

.c-newsletter__body {
  position: relative;
  margin: 56px auto 0;
}

@media (min-width: 768px) {
  .c-newsletter__body {
    margin-top: 64px;
  }
}

@media (min-width: 1024px) {
  .c-newsletter__body {
    margin-bottom: 20px;
    margin-top: 80px;
  }
}

@media (min-width: 1280px) {
  .c-newsletter__body {
    margin-top: 144px;
  }
}

@media (min-width: 1700px) {
  .c-newsletter__body {
    margin-bottom: 30px;
    margin-top: 200px;
  }
}

@media (min-width: 1024px) {
  .c-move-the-needle + .c-newsletter .c-newsletter__body {
    margin-top: 40px;
  }
}

.c-newsletter__info > p {
  position: relative;
  top: 8%;
  font-size: 1.5rem;
  line-height: 1.42;
  margin-bottom: 64px;
}

@media (min-width: 480px) {
  .c-newsletter__info > p {
    font-size: 1.6rem;
    margin-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .c-newsletter__info > p {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-newsletter__info > p {
    font-size: 2rem;
  }
}

@media (min-width: 1700px) {
  .c-newsletter__info > p {
    font-size: 2.4rem;
  }
}

.c-newsletter__info a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-newsletter__info a:hover, .c-newsletter__info a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-newsletter__form {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .c-newsletter__form {
    margin-bottom: 10px;
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .c-newsletter__form {
    flex-direction: row;
    margin-bottom: 30px;
  }
}

@media (min-width: 1700px) {
  .c-newsletter__form {
    margin-bottom: 30px;
  }
}

.c-newsletter__form-group {
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .c-newsletter__form-group {
    margin-bottom: 0;
  }
}

.c-newsletter__form-label {
  font-size: 1.5rem;
  font-variant: small-caps;
  color: #4a4e4d;
  text-transform: lowercase;
  margin-bottom: 8px;
  line-height: 2rem;
}

@media (min-width: 768px) {
  .c-newsletter__form-label {
    font-size: 1.6rem;
  }
}

@media (min-width: 768px) {
  .c-newsletter__form-label {
    line-height: 1.6em;
  }
}

@media (min-width: 1700px) {
  .c-newsletter__form-label {
    font-size: 1.8rem;
  }
}

.c-newsletter__form-input {
  font-size: 1.8rem;
  border-color: #fff;
  padding: 1rem 1.2rem 0.9rem;
}

@media (min-width: 1700px) {
  .c-newsletter__form-input {
    font-size: 2.4rem;
    padding: 0.8rem 1.6rem 0.7rem;
  }
}

.c-newsletter__form-input:hover {
  cursor: pointer;
}

.c-newsletter__form-input::-webkit-input-placeholder {
  color: #bbb;
}

.c-newsletter__form-input::-moz-placeholder {
  color: #bbb;
}

.c-newsletter__form-input:-moz-placeholder {
  color: #bbb;
}

.c-newsletter__form-input:-ms-input-placeholder {
  color: #bbb;
}

.c-newsletter__form-input:focus {
  border-color: #c1a0a8;
  cursor: text;
}

.c-newsletter__form-input:focus::-webkit-input-placeholder {
  color: #fff;
}

.c-newsletter__form-input:focus::-moz-placeholder {
  color: #fff;
}

.c-newsletter__form-input:focus:-moz-placeholder {
  color: #fff;
}

.c-newsletter__form-input:focus:-ms-input-placeholder {
  color: #fff;
}

.c-newsletter__form-input.parsley-error {
  border-color: #ff8b8b;
  box-shadow: inset 0 0 8px 0 #ff8b8b;
}

.c-newsletter__form-button-container {
  margin-top: 10px;
  align-self: flex-end;
}

@media (min-width: 768px) {
  .c-newsletter__form-button-container {
    margin-top: 15px;
  }
}

.c-newsletter__form-button {
  align-self: flex-end;
  width: 100%;
}

@media (min-width: 480px) {
  .c-newsletter__form-button {
    width: auto;
  }
}

.c-post-body__paragraph,
.c-post-body__copy {
  color: #525857;
}

.c-post-body__paragraph a,
.c-post-body__copy a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-post-body__paragraph a:hover, .c-post-body__paragraph a:focus,
.c-post-body__copy a:hover,
.c-post-body__copy a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-post-body__paragraph--spacious,
.c-post-body__copy--spacious {
  line-height: 2em;
}

.c-post-body__paragraph ul, .c-post-body__paragraph ol,
.c-post-body__copy ul,
.c-post-body__copy ol {
  margin: 24px 0 24px 15px;
  position: relative;
}

@media (min-width: 1024px) {
  .c-post-body__paragraph ul, .c-post-body__paragraph ol,
  .c-post-body__copy ul,
  .c-post-body__copy ol {
    margin: 40px 0 40px 20px;
  }
}

.c-post-body__paragraph ul li, .c-post-body__paragraph ol li,
.c-post-body__copy ul li,
.c-post-body__copy ol li {
  margin-bottom: 16px;
  padding-left: 8px;
}

.c-post-body__copy-button {
  margin-bottom: 32px;
}

.c-post-body__paragraph {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-post-body__paragraph {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-post-body__paragraph {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-body__paragraph {
    font-size: 2.4rem;
  }
}

.c-post-body__paragraph p {
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  .c-post-body__paragraph p {
    margin-bottom: 40px;
  }
}

.c-post-body__paragraph p:last-of-type {
  margin-bottom: 0;
}

.c-post-body__copy {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-post-body__copy {
    font-size: 1.7rem;
  }
}

@media (min-width: 1024px) {
  .c-post-body__copy {
    font-size: 1.8rem;
  }
}

@media (min-width: 1280px) {
  .c-post-body__copy {
    font-size: 2rem;
  }
}

.c-post-body__copy p + p {
  margin-top: 24px;
}

@media (min-width: 1024px) {
  .c-post-body__copy p + p {
    margin-top: 32px;
  }
}

.c-post-body__copy--larger {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .c-post-body__copy--larger {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-post-body__copy--larger {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-body__copy--larger {
    font-size: 2.4rem;
  }
}

@media (min-width: 768px) {
  .c-post-body__copy--larger {
    margin-bottom: 32px;
  }
}

.c-post-body__copy--no-mb {
  margin-bottom: 0;
}

.c-post-body__row {
  padding-top: 24px;
  margin-bottom: 0;
}

@media (min-width: 480px) {
  .c-post-body__row {
    padding-top: 32px;
  }
}

@media (min-width: 768px) {
  .c-post-body__row {
    padding-top: 40px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__row {
    padding-top: 64px;
  }
}

@media (min-width: 1280px) {
  .c-post-body__row {
    padding-top: 72px;
  }
}

.c-post-body__row--no-top {
  padding-top: 0;
}

.c-post-body__row--less-padding {
  padding-top: 24px;
}

@media (min-width: 768px) {
  .c-post-body__row--less-padding {
    padding-top: 32px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__row--less-padding {
    padding-top: 40px;
  }
}

@media (min-width: 1280px) {
  .c-post-body__row--less-padding {
    padding-top: 48px;
  }
}

.c-post-body__row--more-padding {
  padding-top: 40px;
}

@media (min-width: 480px) {
  .c-post-body__row--more-padding {
    padding-top: 48px;
  }
}

@media (min-width: 768px) {
  .c-post-body__row--more-padding {
    padding-top: 56px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__row--more-padding {
    padding-top: 72px;
  }
}

@media (min-width: 1280px) {
  .c-post-body__row--more-padding {
    padding-top: 96px;
  }
}

.c-post-body__row:first-child {
  padding-top: 0;
}

.c-post-body__midheading {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 3rem;
  text-transform: uppercase;
}

.c-post-body__co::after {
  content: "";
  display: table;
  clear: both;
}

.c-post-body__co--awards-press {
  padding-top: 32px;
}

.c-post-body__co-image {
  margin-bottom: 1.6rem;
  height: 7em;
}

.c-post-body__co-image--placeholder {
  display: none;
}

@media (min-width: 768px) {
  .c-post-body__co-image--placeholder {
    display: block;
  }
}

.c-post-body__co-header,
.c-post-body__paragraph h2,
.c-post-body__copy h3 {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .c-post-body__co-header,
  .c-post-body__paragraph h2,
  .c-post-body__copy h3 {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-post-body__co-header,
  .c-post-body__paragraph h2,
  .c-post-body__copy h3 {
    font-size: 3rem;
  }
}

.c-post-body__co-header--no-mt {
  margin-top: 0;
}

.c-post-body__co-body {
  font-style: italic;
  color: #858888;
  margin: 0 0 2.4rem;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-post-body__co-body {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-post-body__co-body {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-body__co-body {
    font-size: 2.4rem;
  }
}

@media (min-width: 768px) {
  .c-post-body__co-body {
    margin: 0;
  }
}

.c-post-body__co-body p {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .c-post-body__co-body p {
    margin-bottom: 24px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__co-body p {
    margin-bottom: 32px;
  }
}

.c-post-body__co-body p:last-of-type {
  margin-bottom: 0;
}

.c-post-body__co-body a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
  color: #858888;
}

.c-post-body__co-body a:hover, .c-post-body__co-body a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-post-body__co-body--no-mb {
  margin-bottom: 0;
}

.c-post-body__before {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .c-post-body__before {
    margin-top: 36px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__before {
    margin-top: 60px;
  }
}

.c-post-body__before-co {
  margin-bottom: 24px;
  margin-top: 24px;
}

@media (min-width: 768px) {
  .c-post-body__before-co {
    margin-top: 32px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__before-co {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .c-post-body__before-co {
    margin-top: 70px;
    margin-bottom: 48px;
  }
}

.c-post-body__after-co--awards-press {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .c-post-body__after-co {
    margin-top: 40px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__after-co {
    margin-top: 60px;
  }
}

@media (min-width: 1280px) {
  .c-post-body__after-co {
    margin-top: 70px;
    margin-bottom: 10px;
  }
}

.c-post-body__after-co--more-posts {
  margin-bottom: 24px;
  margin-top: 32px;
}

@media (min-width: 768px) {
  .c-post-body__after-co--more-posts {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__after-co--more-posts {
    margin-top: 48px;
    margin-bottom: 56px;
  }
}

@media (min-width: 1280px) {
  .c-post-body__after-co--more-posts {
    margin-top: 72px;
    margin-bottom: 80px;
  }
}

.c-post-body__link {
  font-size: 1.8rem;
}

@media (min-width: 768px) {
  .c-post-body__link {
    font-size: 2.4rem;
  }
}

@media (min-width: 1700px) {
  .c-post-body__link {
    font-size: 3rem;
  }
}

.c-post-body__link-icon {
  color: #85C9D3;
  width: 24px;
  height: 24px;
  font-size: 24px;
  display: block;
  float: left;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .c-post-body__link-icon {
    margin-top: 3px;
    margin-right: 16px;
  }
}

@media (min-width: 1700px) {
  .c-post-body__link-icon {
    width: 36px;
    height: 36px;
    font-size: 36px;
    margin-top: 0;
  }
}

.c-post-body__link-link {
  display: block;
}

@media (min-width: 1024px) {
  .c-post-body__link-link {
    margin-left: -40px;
  }
}

@media (min-width: 1700px) {
  .c-post-body__link-link {
    margin-left: -52px;
  }
}

.c-post-body__link-desc {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-post-body__link-desc:hover, .c-post-body__link-desc:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-post-body__quote-container {
  border-left: 4px solid #87d1cb;
  padding-left: 20px;
  padding-top: 6px;
}

@media (min-width: 768px) {
  .c-post-body__quote-container {
    border-left-width: 6px;
    padding-left: 24px;
    padding-top: 8px;
  }
}

@media (min-width: 1024px) {
  .c-post-body__quote-container {
    border-left-width: 8px;
    padding-left: 30px;
    padding-top: 12px;
  }
}

@media (min-width: 1280px) {
  .c-post-body__quote-container {
    padding-left: 36px;
  }
}

.c-post-body__quote-text, .c-post-body__quote-text > p {
  font-size: 2rem;
  font-style: italic;
  line-height: 1.25;
}

@media (min-width: 375px) {
  .c-post-body__quote-text, .c-post-body__quote-text > p {
    font-size: 2.2rem;
  }
}

@media (min-width: 768px) {
  .c-post-body__quote-text, .c-post-body__quote-text > p {
    font-size: 2.6rem;
  }
}

@media (min-width: 1024px) {
  .c-post-body__quote-text, .c-post-body__quote-text > p {
    font-size: 3.2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-body__quote-text, .c-post-body__quote-text > p {
    font-size: 4rem;
  }
}

.c-post-body__quote-text > p {
  display: inline;
  margin-bottom: 0;
}

.c-post-body__quote-text:before {
  content: '“';
  position: absolute;
  transform: translateX(-100%);
  padding-right: 3px;
}

.c-post-body__quote-text:after {
  content: '”';
  margin-left: -4px;
}

.c-post-body__quote-text a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-post-body__quote-text a:hover, .c-post-body__quote-text a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-post-body__quote-author {
  font-size: 1.8rem;
  font-variant: small-caps;
  color: #858888;
  text-transform: lowercase;
  margin-top: 1.3rem;
}

@media (min-width: 768px) {
  .c-post-body__quote-author {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-post-body__quote-author {
    font-size: 2.2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-body__quote-author {
    font-size: 2.2rem;
  }
}

.c-post-body__quote-author p {
  margin-bottom: 0;
}

.c-post-body__image {
  height: auto;
  display: inline-block;
  max-width: 100%;
}

.c-post-image__caption {
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 1.34;
  color: #858888;
}

@media (min-width: 1024px) {
  .c-post-image__caption {
    font-size: 1.5rem;
  }
}

@media (min-width: 1700px) {
  .c-post-image__caption {
    font-size: 1.8rem;
  }
}

.c-post-image__caption p {
  margin-bottom: 0;
}

.c-post-breadcrumb {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .c-post-breadcrumb {
    margin-bottom: 6rem;
  }
}

.c-post-breadcrumb--on-banner {
  color: #2c2f2e;
  left: 0;
  position: absolute;
  right: 0;
  top: 24px;
  z-index: 100;
}

@media (min-width: 768px) {
  .c-post-breadcrumb--on-banner {
    top: 40px;
  }
}

.c-post-breadcrumb__link {
  background-color: #fff;
  border-radius: 2.5px;
  font-variant: small-caps;
  color: #4a4e4d;
  text-transform: lowercase;
  font-size: 1.6rem;
  padding: 11px 16px 12px;
  margin-left: -16px;
}

@media (min-width: 1700px) {
  .c-post-breadcrumb__link {
    padding: 14px 16px;
    font-size: 1.7rem;
  }
}

.c-post-breadcrumb--on-banner .c-post-breadcrumb__link {
  background-color: rgba(255, 255, 255, 0.85);
  margin-left: 0;
}

.c-post-breadcrumb__link:hover, .c-post-breadcrumb__link:focus {
  background-color: #f7f7f7;
}

.c-post-breadcrumb__text {
  display: inline-block;
  vertical-align: middle;
}

.c-post-breadcrumb__icon:before {
  content: "➔";
  display: inline-block;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-right: 6px;
  margin-top: -2px;
  position: relative;
  transform: rotateZ(180deg);
  vertical-align: middle;
}

.c-post-header__image {
  display: none;
  position: relative;
}

.c-post-header__image:before {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  bottom: 0;
  content: "";
  height: 32px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 0;
}

@media (min-width: 768px) {
  .c-post-header__image:before {
    height: 48px;
  }
}

@media (min-width: 1024px) {
  .c-post-header__image:before {
    height: 60px;
  }
}

.c-post-header__bg-image-outer {
  max-height: 480px;
  position: relative;
  overflow: hidden;
}

.c-post-header__bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  padding-bottom: 43.75%;
}

.c-post-header__image-image {
  display: block;
  width: 100%;
}

.c-post-header__bottom {
  position: relative;
  padding-top: 3rem;
}

@media (min-width: 480px) {
  .c-post-header__bottom {
    padding-top: 4rem;
  }
}

@media (min-width: 768px) {
  .c-post-header__bottom {
    padding-top: 6rem;
  }
}

@media (min-width: 1700px) {
  .c-post-header__bottom {
    padding-top: 9rem;
  }
}

.c-post-header__bottom--no-banner {
  padding-top: 3.6rem;
}

@media (min-width: 768px) {
  .c-post-header__bottom--no-banner {
    padding-top: 4rem;
  }
}

.c-post-header__avatar {
  position: absolute;
  overflow: hidden;
  border: 2px solid #fff;
  top: -46.62px;
  border-radius: 35px;
  width: 70px;
  height: 70px;
}

@media (min-width: 768px) {
  .c-post-header__avatar {
    top: -53.28px;
    border-radius: 40px;
    width: 80px;
    height: 80px;
    left: calc(6.25% + 30px);
  }
}

@media (min-width: 1024px) {
  .c-post-header__avatar {
    top: -79.92px;
    border-radius: 60px;
    width: 120px;
    height: 120px;
    border-width: 3px;
  }
}

@media (min-width: 1700px) {
  .c-post-header__avatar {
    top: -99.9px;
    border-radius: 75px;
    width: 150px;
    height: 150px;
    border-width: 3px;
    left: auto;
  }
}

.c-post-header__avatar-image {
  width: 100%;
  height: 100%;
}

.c-post-header__heading {
  margin-bottom: 0;
  font-size: 3.4rem;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: -0.01em;
}

@media (min-width: 480px) {
  .c-post-header__heading {
    font-size: 3.6rem;
  }
}

@media (min-width: 768px) {
  .c-post-header__heading {
    font-size: 4.2rem;
  }
}

@media (min-width: 1024px) {
  .c-post-header__heading {
    font-size: 4.8rem;
  }
}

@media (min-width: 1280px) {
  .c-post-header__heading {
    font-size: 6.4rem;
  }
}

@media (min-width: 1700px) {
  .c-post-header__heading {
    font-size: 7rem;
    letter-spacing: -0.02em;
  }
}

.c-post-header__short {
  margin-bottom: 0;
  margin-top: 2rem;
  font-size: 1.8rem;
  font-style: italic;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-post-header__short {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-post-header__short {
    font-size: 2.2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-header__short {
    font-size: 2.6rem;
    margin-top: 2.4rem;
  }
}

@media (min-width: 1700px) {
  .c-post-header__short {
    font-size: 3rem;
    letter-spacing: -0.2px;
  }
}

.c-post-header__client,
.c-post-header__services {
  margin-top: 1.6rem;
  font-size: 1.8rem;
  line-height: 1.6;
}

@media (min-width: 768px) {
  .c-post-header__client,
  .c-post-header__services {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-header__client,
  .c-post-header__services {
    font-size: 2.4rem;
  }
}

@media (min-width: 1700px) {
  .c-post-header__client,
  .c-post-header__services {
    margin-top: 2.2rem;
  }
}

.c-post-header__services {
  margin-top: 0.8rem;
}

@media (min-width: 1700px) {
  .c-post-header__services {
    margin-top: 1.1rem;
  }
}

.c-post-header__client-label,
.c-post-header__services-label {
  color: #858888;
}

.c-post-header__client-link,
.c-post-header__services-link {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-post-header__client-link:hover, .c-post-header__client-link:focus,
.c-post-header__services-link:hover,
.c-post-header__services-link:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

.c-post-header__byline {
  padding-top: 0;
  color: #858888;
  text-transform: lowercase;
  font-variant: small-caps;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-post-header__byline {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-post-header__byline {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-header__byline {
    font-size: 2.4rem;
  }
}

.c-post-header__byline a {
  color: #858888;
}

.c-post-header__byline a:hover, .c-post-header__byline a:focus {
  text-decoration: underline;
}

.c-post-header__date {
  white-space: nowrap;
}

@media (min-width: 1024px) {
  .c-post-header__title-buffer {
    margin-top: 16px;
  }
}

.c-post-image__image {
  margin-bottom: 1rem;
  width: 100%;
}

@media (min-width: 1024px) {
  .c-post-image__image {
    margin-bottom: 0;
  }
}

@media (max-width: 479px) {
  .c-post-image__content {
    margin-left: 0;
    width: calc(100% + 32px);
  }
}

@media (max-width: 767px) {
  .c-post-image__content--padding {
    width: 100%;
  }
}

.c-post-image__frame-container {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 1.2rem;
}

.c-post-image__frame,
.c-post-image__frame-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.c-post-image__container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .c-post-image__container {
    flex-direction: row;
  }
}

.c-post-image__description {
  font-size: 1.4rem;
  line-height: 1.34;
  color: #858888;
}

@media (min-width: 1024px) {
  .c-post-image__description {
    font-size: 1.5rem;
  }
}

@media (min-width: 1700px) {
  .c-post-image__description {
    font-size: 1.8rem;
  }
}

.c-post-image__description--left {
  text-align: right;
  display: none;
}

@media (min-width: 1024px) {
  .c-post-image__description--left {
    display: block;
  }
}

.c-post-image__description--bottom {
  display: block;
}

@media (min-width: 1024px) {
  .c-post-image__description--bottom {
    display: none;
  }
}

.c-post-image__description p {
  margin-bottom: 0;
}

.c-post-navigation {
  margin-bottom: 0;
  margin-top: 2.4rem;
  position: relative;
  line-height: 1.42;
}

@media (min-width: 480px) {
  .c-post-navigation {
    position: static;
  }
}

@media (min-width: 768px) {
  .c-post-navigation {
    margin-top: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-post-navigation {
    margin-top: 2.4rem;
  }
}

.c-post-navigation__label {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
  margin-bottom: 12px;
  line-height: 1;
  margin-top: 4px;
}

@media (min-width: 768px) {
  .c-post-navigation__label {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-post-navigation__label {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .c-post-navigation__label {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-post-navigation__label {
    margin-top: 6px;
  }
}

.c-post-navigation__link {
  font-size: 1.8rem;
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-post-navigation__link:hover, .c-post-navigation__link:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

@media (min-width: 1280px) {
  .c-post-navigation__link {
    font-size: 2rem;
  }
}

@media (min-width: 1700px) {
  .c-post-navigation__link {
    font-size: 2.4rem;
  }
}

.c-post-social {
  margin-bottom: 2.4rem;
  padding-left: 6px;
}

@media (min-width: 480px) {
  .c-post-social {
    padding-left: 22px;
  }
}

@media (min-width: 768px) {
  .c-post-social {
    position: absolute;
    right: 0;
    top: calc(100vw * (560 / 1920) / 2);
    bottom: calc(100vw * (560 / 1920) / 2);
    pointer-events: none;
    z-index: 100;
  }
}

@media (min-width: 768px) {
  .c-post-social__inner {
    display: block;
    transform: translateY(-50%);
    position: absolute;
    top: 0;
    right: 0;
    width: 6.25vw;
    border: 1px solid #dae5e4;
    border-right-width: 0;
    background-color: #fff;
    pointer-events: auto;
    max-width: 64px;
  }
  @supports (position: -webkit-sticky) {
    .c-post-social__inner {
      position: -webkit-sticky;
      top: 50%;
    }
  }
  @supports (position: sticky) {
    .c-post-social__inner {
      position: sticky;
      top: 50%;
    }
  }
  .c-post-social__inner.is-fixed {
    position: fixed;
    top: 50%;
  }
  .c-post-social__inner.is-docked {
    position: absolute;
    top: auto;
    bottom: 0;
  }
}

.c-post-social__link {
  display: inline-block;
  text-align: center;
  padding: 8px 10px;
}

@media (min-width: 768px) {
  .c-post-social__link {
    display: block;
  }
}

@media (min-width: 1024px) {
  .c-post-social__link {
    padding: 10px;
  }
}

@media (min-width: 1280px) {
  .c-post-social__link {
    padding: 12px;
  }
}

@media (min-width: 1700px) {
  .c-post-social__link {
    padding: 15px 10px 14px;
  }
}

@media (min-width: 768px) {
  .c-post-social__link:first-child {
    margin-top: 5px;
  }
}

@media (min-width: 1700px) {
  .c-post-social__link:first-child {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .c-post-social__link:last-child {
    margin-bottom: 6px;
  }
}

@media (min-width: 1700px) {
  .c-post-social__link:last-child {
    margin-bottom: 10px;
  }
}

.c-post-social__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  display: inline-block;
  transition: color .2s ease;
}

@media (min-width: 1700px) {
  .c-post-social__icon {
    width: 30px;
    height: 30px;
    font-size: 30px;
  }
}

.c-post-social__icon:hover, .c-post-social__icon:focus {
  color: #4a4e4d;
}

.c-separator {
  height: 2px;
}

.c-separator--thick {
  height: 4px;
}

@media (min-width: 768px) {
  .c-separator--thick {
    height: 6px;
  }
}

@media (min-width: 1024px) {
  .c-separator--thick {
    height: 8px;
  }
}

.c-separator--full-width {
  width: 100%;
}

.c-separator--light-blue {
  background-color: #87d1cb;
}

.c-separator--light-grey {
  background-color: #858888;
}

.c-separator--lighter-grey {
  background-color: #e4e7e7;
}

.c-service-section {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .c-service-section {
    margin-top: 40px;
  }
}

@media (min-width: 1024px) {
  .c-service-section {
    margin-top: 72px;
  }
}

.c-service-section--first {
  margin-top: 0;
}

@media (min-width: 768px) {
  .c-service-section__row--medium {
    margin-top: 24px;
  }
}

.c-service-section__row--large {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .c-service-section__row--large {
    margin-top: 48px;
  }
}

.c-service-section__row--reverse-order {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 768px) {
  .c-service-section__row--center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .c-service-section__row--bottom {
    display: flex;
    flex-direction: row;
    align-items: bottom;
  }
}

.c-service-section__block {
  margin-bottom: 16px;
}

.c-service-section__block--last-block {
  margin-bottom: 40px;
}

.c-service-section__title {
  padding-top: 12px;
}

@media (min-width: 1280px) {
  .c-service-section__title {
    margin-bottom: 24px;
  }
}

.c-service-section__block ul, .c-service-section__block ol {
  margin-top: 0 0 40px 20px;
}

.c-service-section__block ul + ul, .c-service-section__block ul + ol, .c-service-section__block ol + ul, .c-service-section__block ol + ol, .c-service-section__block p + ul, .c-service-section__block p + ol {
  margin-top: 24px;
}

@media (min-width: 1024px) {
  .c-service-section__block ul + ul, .c-service-section__block ul + ol, .c-service-section__block ol + ul, .c-service-section__block ol + ol, .c-service-section__block p + ul, .c-service-section__block p + ol {
    margin-top: 32px;
  }
}

.c-share-snippet__quote {
  background: rgba(193, 228, 234, 0.5);
}

.c-share-snippet__popup {
  display: inline-block;
}

a.c-share-snippet__twitter {
  color: #00a2ee;
}

a.c-share-snippet__linkedin {
  color: #0077B2;
}

a.c-share-snippet__icon {
  box-sizing: content-box;
  display: inline-block;
  font-size: 14px;
  margin-left: 2px;
  padding: 0 4px;
}

a.c-share-snippet__icon:not(:hover):not(:focus) {
  box-shadow: inset 0 0 0 #c1e4ea;
}

a.c-share-snippet__icon:hover, a.c-share-snippet__icon:focus {
  box-shadow: inset 0 0 0 #c1e4ea;
}

@media (min-width: 768px) {
  a.c-share-snippet__icon {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  a.c-share-snippet__icon {
    font-size: 18px;
  }
}

a.c-share-snippet__icon:first-child {
  margin-left: 6px;
}

.c-share-snippet__icon-share {
  color: #0086a1;
  font-size: 16px;
}

.c-standard-body__before-co {
  margin-top: 32px;
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  .c-standard-body__before-co {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .c-standard-body__before-co {
    margin-top: 70px;
    margin-bottom: 48px;
  }
}

.c-standard-body__paragraph h2 {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;
  margin: 1em 0 2.4rem;
}

@media (min-width: 768px) {
  .c-standard-body__paragraph h2 {
    font-size: 2.4rem;
  }
}

@media (min-width: 1280px) {
  .c-standard-body__paragraph h2 {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .c-standard-body__paragraph h2 {
    margin-bottom: 40px;
  }
}

.c-standard-body__paragraph img {
  max-width: 100%;
}

.c-standard-body__paragraph {
  color: #525857;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

.c-standard-body__paragraph a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-standard-body__paragraph a:hover, .c-standard-body__paragraph a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

@media (min-width: 768px) {
  .c-standard-body__paragraph {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-standard-body__paragraph {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-standard-body__paragraph {
    font-size: 2.4rem;
  }
}

.c-standard-body__paragraph--spacious {
  line-height: 2em;
}

.c-standard-body__paragraph p {
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  .c-standard-body__paragraph p {
    margin-bottom: 40px;
  }
}

.c-standard-body__paragraph p:last-of-type {
  margin-bottom: 0;
}

.c-standard-body__paragraph ul, .c-standard-body__paragraph ol {
  margin: 24px 0 24px 15px;
  position: relative;
}

@media (min-width: 1024px) {
  .c-standard-body__paragraph ul, .c-standard-body__paragraph ol {
    margin: 40px 0 40px 20px;
  }
}

.c-standard-body__paragraph ul li, .c-standard-body__paragraph ol li {
  margin-bottom: 16px;
  padding-left: 8px;
}

.c-standard-header__heading {
  margin-bottom: 0;
  font-size: 2.6rem;
  font-weight: normal;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .c-standard-header__heading {
    font-size: 3.6rem;
  }
}

@media (min-width: 1024px) {
  .c-standard-header__heading {
    font-size: 4rem;
  }
}

@media (min-width: 1280px) {
  .c-standard-header__heading {
    font-size: 6rem;
  }
}

@media (min-width: 1700px) {
  .c-standard-header__heading {
    line-height: 1.14;
    font-size: 8.4rem;
  }
}

.c-standard-page {
  font-size: 0.6rem;
  padding-bottom: 8em;
  position: relative;
}

@media (min-width: 768px) {
  .c-standard-page {
    font-size: 0.8rem;
  }
}

@media (min-width: 1024px) {
  .c-standard-page {
    font-size: 1rem;
  }
}

.c-story-body {
  font-size: 0.6rem;
}

@media (min-width: 1024px) {
  .c-story-body {
    font-size: 1rem;
  }
}

.c-story-body__bg-image {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  display: block;
}

@media (min-width: 768px) {
  .c-story-body__bg-image {
    max-width: 1920px;
  }
}

.c-story-body__bg-image-inner {
  display: block;
  margin: auto;
  width: 100%;
}

.c-story-body__row {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
}

@media (min-width: 768px) {
  .c-story-body__row {
    flex-direction: row;
  }
}

.c-story-body__row--image-second {
  flex-direction: column;
}

@media (min-width: 768px) {
  .c-story-body__row--image-second {
    flex-direction: row;
  }
}

.c-story-body__row--center {
  justify-content: center;
}

.c-story-body__column {
  width: 100%;
  padding: 40px 15px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .c-story-body__column {
    padding: 48px 15px;
    width: 50%;
  }
}

.c-story-body__column:first-child {
  justify-content: flex-end;
}

.c-story-body__column--to-bottom {
  align-items: flex-end;
  padding-bottom: 0;
}

.c-story-body__column--background-left, .c-story-body__column--background-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
}

@media (min-width: 768px) {
  .c-story-body__column--background-left, .c-story-body__column--background-right {
    width: 50%;
    height: 100%;
    min-height: 0;
  }
}

.c-story-body__column--background-right {
  top: 50%;
}

@media (min-width: 768px) {
  .c-story-body__column--background-right {
    left: auto;
    right: 0;
    top: 0;
  }
}

.c-story-body__column--image {
  padding: 0;
}

.c-story-body__column-body {
  width: 100%;
  max-width: 730px;
}

@media (min-width: 768px) {
  .c-story-body__column-body {
    padding: 0 6.25%;
  }
}

.c-story-body__column--image .c-story-body__column-body {
  padding: 0;
}

.c-story-body__heading-img {
  height: 3.5em;
  max-width: 100%;
}

.c-story-body__paragraph {
  text-align: left;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

.c-story-body__paragraph a {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-story-body__paragraph a:hover, .c-story-body__paragraph a:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

@media (min-width: 768px) {
  .c-story-body__paragraph {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-story-body__paragraph {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-story-body__paragraph {
    font-size: 2.4rem;
  }
}

.c-story-body__paragraph p {
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  .c-story-body__paragraph p {
    margin-bottom: 40px;
  }
}

.c-story-body__paragraph:last-of-type p:last-of-type {
  margin-bottom: 0;
}

.c-story-body__image {
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
}

.c-team-page {
  font-size: 0.6rem;
  margin-bottom: -5em;
}

@media (min-width: 1024px) {
  .c-team-page {
    font-size: 1rem;
  }
}

.c-team-page__people-filler {
  display: none;
}

@media (min-width: 1024px) {
  .c-team-page__people-filler:nth-child(3n) {
    display: block;
  }
}

.c-team-person {
  margin-bottom: 4em;
}

@media (min-width: 1024px) {
  .c-team-person {
    margin-bottom: 6em;
  }
}

@media (min-width: 1280px) {
  .c-team-person {
    margin-bottom: 10em;
  }
}

.c-team-person__top {
  margin-bottom: 1.6rem;
}

@media (min-width: 768px) {
  .c-team-person__top {
    margin-bottom: 2.4rem;
  }
}

.c-team-person__top-image {
  width: 100%;
}

.c-team-person__name {
  margin: 0 0 8px;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .c-team-person__name {
    font-size: 2.8rem;
  }
}

@media (min-width: 1280px) {
  .c-team-person__name {
    font-size: 3rem;
  }
}

.c-team-person__position {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-variant: small-caps;
  color: #858888;
  text-transform: lowercase;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-team-person__position {
    font-size: 1.8rem;
  }
}

.c-team-person__description {
  margin-bottom: 0;
  color: #525857;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
}

@media (min-width: 768px) {
  .c-team-person__description {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-team-person__description {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-team-person__description {
    font-size: 2.4rem;
  }
}

.c-team-person__description p:last-of-type {
  margin-bottom: 0;
}

.c-teaser {
  margin-bottom: 56px;
}

@media (min-width: 768px) {
  .c-teaser {
    margin-bottom: 72px;
  }
}

@media (min-width: 1024px) {
  .c-teaser {
    margin-bottom: 120px;
  }
}

.c-teaser__summary {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .c-teaser__summary {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-teaser__summary {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-teaser__summary {
    font-size: 2.4rem;
  }
}

@media (min-width: 1024px) {
  .c-teaser__summary {
    margin-bottom: 32px;
  }
}

.c-teaser__heading {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 12px;
}

@media (min-width: 480px) {
  .c-teaser__heading {
    font-size: 2.2rem;
  }
}

@media (min-width: 768px) {
  .c-teaser__heading {
    font-size: 2.8rem;
    margin-bottom: 24px;
  }
}

@media (min-width: 1280px) {
  .c-teaser__heading {
    font-size: 3.2rem;
  }
}

@media (min-width: 1700px) {
  .c-teaser__heading {
    font-size: 3.4rem;
  }
}

.c-teaser__read-more {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
  font-size: 2rem;
}

.c-teaser__read-more:hover, .c-teaser__read-more:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

@media (min-width: 768px) {
  .c-teaser__read-more {
    font-size: 2.2rem;
  }
}

@media (min-width: 1024px) {
  .c-teaser__read-more {
    font-size: 2.4rem;
  }
}

.c-tile-square {
  position: relative;
  margin-bottom: 32px;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 6px 16px 3px rgba(44, 47, 46, 0.08);
}

@media (min-width: 480px) {
  .c-tile-square {
    margin-bottom: 24px;
  }
}

@media (min-width: 768px) {
  .c-tile-square {
    margin-bottom: 32px;
  }
}

@media (min-width: 1024px) {
  .c-tile-square::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

@media (min-width: 1024px) {
  .c-tile-square {
    box-shadow: 0 16px 29.44px 2.56px rgba(44, 47, 46, 0.1);
    margin-bottom: 48px;
  }
}

@media (min-width: 1280px) {
  .c-tile-square {
    margin-bottom: 56px;
  }
}

.c-tile-square--link {
  transition-property: transform;
  transition-duration: 0.15s;
}

.c-tile-square--link:hover {
  transform: translateY(-16px);
}

@media (min-width: 768px) {
  .c-tile-square--expand-vertically::before {
    padding-bottom: 112%;
  }
}

.c-tile-square__inner {
  display: flex;
  flex-flow: column;
}

@media (min-width: 1024px) {
  .c-tile-square__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.c-tile-square__image-wrapper {
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
}

@media (min-width: 1024px) {
  .c-tile-square__image-wrapper {
    height: 1%;
  }
}

.c-tile-square__image-wrapper:before {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
  bottom: 0;
  content: "";
  height: 48px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}

.c-tile-square__image {
  display: block;
  border-radius: 6px 6px 0 0;
  width: 100%;
}

@media (min-width: 1024px) {
  .c-tile-square__image {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    min-height: 100%;
  }
  @supports (object-fit: cover) {
    .c-tile-square__image {
      transform: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.c-tile-square__content-wrapper {
  background: #fff;
  padding: 24px;
  font-size: 0.6rem;
  min-height: 1px;
}

@media (min-width: 480px) {
  .c-tile-square__content-wrapper {
    padding: 16px;
  }
}

@media (min-width: 768px) {
  .c-tile-square__content-wrapper {
    font-size: 0.7rem;
    padding: 24px 28px;
  }
}

@media (min-width: 1024px) {
  .c-tile-square__content-wrapper {
    padding: 30px 36px;
    font-size: 0.8rem;
  }
}

@media (min-width: 1700px) {
  .c-tile-square__content-wrapper {
    padding: 40px 60px 55px;
    font-size: 1rem;
  }
}

.c-tile-square__info-text {
  margin-bottom: 1.5em;
  font-size: 2rem;
  font-variant: small-caps;
  text-transform: lowercase;
  color: #858888;
}

.c-tile-square__title {
  margin: 0;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
}

@media (min-width: 480px) {
  .c-tile-square__title {
    font-size: 2.2rem;
  }
}

@media (min-width: 768px) {
  .c-tile-square__title {
    font-size: 2.8rem;
  }
}

@media (min-width: 1024px) {
  .c-tile-square__title {
    font-size: 3.2rem;
  }
}

@media (min-width: 1280px) {
  .c-tile-square__title {
    font-size: 4rem;
    letter-spacing: -0.8px;
  }
}

@media (min-width: 1700px) {
  .c-tile-square__title {
    font-size: 4.8rem;
    letter-spacing: -0.8px;
  }
}

.c-tile-square__text {
  margin: 1em 0 0;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.42;
  color: #525857;
}

@media (min-width: 768px) {
  .c-tile-square__text {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-tile-square__text {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-tile-square__text {
    font-size: 2.4rem;
  }
}

.c-tile-square__bottom {
  margin-top: 1.2rem;
}

.c-tile-square__bottom::after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 480px) {
  .c-tile-square__bottom {
    display: none;
  }
}

@media (min-width: 768px) {
  .c-tile-square__bottom {
    display: flex;
    margin-top: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-tile-square__bottom {
    margin-top: 2.8rem;
  }
}

@media (min-width: 1700px) {
  .c-tile-square__bottom {
    margin-top: 3.6rem;
  }
}

.c-tile-square__small-text {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.66;
  color: #858888;
}

@media (min-width: 768px) {
  .c-tile-square__small-text {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .c-tile-square__small-text {
    line-height: 1.5;
  }
}

.c-tile-square__small-image {
  height: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .c-tile-square__small-image {
    display: none;
  }
}

@media (min-width: 1024px) {
  .c-tile-square__small-image {
    display: block;
  }
}

.c-tile {
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  position: relative;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 6px 16px 3px rgba(44, 47, 46, 0.08);
}

@media (min-width: 480px) {
  .c-tile {
    margin-bottom: 24px;
  }
}

@media (min-width: 768px) {
  .c-tile {
    margin-bottom: 32px;
  }
}

@media (min-width: 1024px) {
  .c-tile {
    box-shadow: 0 16px 29.44px 2.56px rgba(44, 47, 46, 0.1);
    margin-bottom: 48px;
  }
}

@media (min-width: 1280px) {
  .c-tile {
    margin-bottom: 56px;
  }
}

.c-tile--link {
  transition-property: transform;
  transition-duration: 0.15s;
}

.c-tile--link:hover {
  transform: translateY(-16px);
}

.c-tile__inner {
  display: block;
  flex-grow: 2;
  max-width: 100%;
}

.c-tile__image-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
}

.c-tile__image-wrapper:before {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, transparent 100%);
  bottom: 0;
  content: "";
  height: 40px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 0;
}

.c-tile__image {
  border-radius: 6px 6px 0 0;
  width: 100%;
  display: block;
  height: 100%;
}

.c-tile__image--circle {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

@supports (object-fit: cover) {
  .c-tile__image--circle {
    object-fit: cover;
  }
}

.c-tile__content-wrapper {
  position: relative;
  background: #fff;
  padding: 20px;
  font-size: 0.8rem;
}

@media (min-width: 480px) {
  .c-tile__content-wrapper {
    padding: 20px 16px;
  }
}

@media (min-width: 768px) {
  .c-tile__content-wrapper {
    font-size: 0.9rem;
    padding: 24px 20px 20px;
  }
}

@media (min-width: 1280px) {
  .c-tile__content-wrapper {
    font-size: 1rem;
    padding: 30px;
  }
}

@media (min-width: 1700px) {
  .c-tile__content-wrapper {
    padding: 40px;
  }
}

.c-tile__circle {
  position: absolute;
  right: 16px;
  border: 1px solid #fff;
  overflow: hidden;
  object-fit: cover;
  background: #fff;
  transform: translateZ(0);
  top: -31.968px;
  border-radius: 24px;
  width: 48px;
  height: 48px;
}

@media (min-width: 480px) {
  .c-tile__circle {
    top: -27.972px;
    border-radius: 21px;
    width: 42px;
    height: 42px;
    right: 8px;
  }
}

@media (min-width: 768px) {
  .c-tile__circle {
    border-width: 2px;
    top: -39.96px;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    right: 20px;
  }
}

@media (min-width: 1280px) {
  .c-tile__circle {
    top: -49.284px;
    border-radius: 37px;
    width: 74px;
    height: 74px;
  }
}

@media (min-width: 1700px) {
  .c-tile__circle {
    right: 37px;
  }
}

.c-tile__info-text {
  margin-bottom: 1.4rem;
  font-size: 1.5rem;
  font-variant: small-caps;
  text-transform: lowercase;
  color: #858888;
}

@media (min-width: 768px) {
  .c-tile__info-text {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-tile__info-text {
    font-size: 1.8rem;
    letter-spacing: -0.2px;
  }
}

@media (min-width: 480px) {
  .c-tile__info-text span {
    display: none;
  }
}

@media (min-width: 600px) {
  .c-tile__info-text span {
    display: inline;
  }
}

.c-tile__title {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 1.4rem;
}

@media (min-width: 480px) {
  .c-tile__title {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .c-tile__title {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-tile__title {
    font-size: 3rem;
    letter-spacing: -0.2px;
  }
}

.c-tile__text {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.66;
  color: #525857;
}

@media (min-width: 768px) {
  .c-tile__text {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

@media (min-width: 1280px) {
  .c-tile__text {
    font-size: 1.8rem;
  }
}

.c-tile__media {
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 0;
  left: 20px;
  padding: 12px 16px;
  position: absolute;
}

@media (min-width: 480px) {
  .c-tile__media {
    left: 16px;
  }
}

@media (min-width: 768px) {
  .c-tile__media {
    left: 20px;
  }
}

@media (min-width: 1280px) {
  .c-tile__media {
    left: 30px;
  }
}

@media (min-width: 1700px) {
  .c-tile__media {
    left: 40px;
  }
}

.c-tile__media__outlet-image {
  display: inline-block;
  max-height: 20px;
  max-width: 120px;
  vertical-align: middle;
}

.c-tvou-logo {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  padding-bottom: 99%;
  height: 0;
  text-indent: -999em;
}

.c-tvou-logo__link,
.c-tvou-logo__link svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-menu-layer .c-tvou-logo__link {
  background: url("../assets/images/tvou-logo.svg") no-repeat 50% 50%;
  background-size: contain;
}

.c-upload-box {
  transition: padding 0.2s, background 0.2s;
  position: relative;
  display: flex;
  margin-bottom: 28px;
  padding: 15px;
  height: 185px;
  background: rgba(133, 136, 136, 0.1);
  overflow: hidden;
}

.c-upload-box.is-drag-over {
  padding: 30px;
  background: rgba(133, 136, 136, 0.15);
}

@media (min-width: 768px) {
  .c-upload-box {
    margin-bottom: 55px;
  }
}

.c-upload-box__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dashed #858888;
  border-radius: 13px;
}

.c-upload-box__file {
  position: absolute;
  left: -9999px;
}

.c-upload-box__text {
  position: relative;
  z-index: 1;
  padding: 0 20px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
  color: #858888;
  text-align: center;
}

@media (min-width: 768px) {
  .c-upload-box__text {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-upload-box__text {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .c-upload-box__text {
    font-size: 2.4rem;
  }
}

.c-upload-box__link {
  transition: border-color 0.2s;
  color: #2c2f2e;
  border-bottom: 1px solid #c1e4ea;
  cursor: pointer;
}

.c-upload-box__link:hover {
  border-color: #0086a1;
}

.c-upload-box__link--try-again {
  display: none;
}

.c-village-speak {
  margin-bottom: 46px;
  margin-top: 44px;
}

@media (min-width: 768px) {
  .c-village-speak {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media (min-width: 1024px) {
  .c-village-speak {
    margin-top: 150px;
    margin-bottom: 100px;
  }
}

.c-village-speak__header {
  margin-bottom: 1rem;
  width: 100%;
}

@media (min-width: 768px) {
  .c-village-speak__header {
    margin-bottom: 2rem;
    width: 75%;
  }
}

.c-village-speak__header-image {
  width: 100%;
}

.c-website-header {
  padding-top: 1rem;
  height: 135px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .c-website-header {
    margin: 0;
  }
}

.c-website-header.is-fixed {
  position: fixed;
}

.c-website-header.initial-scroll {
  position: absolute;
}

.c-website-header.is-sticked {
  position: fixed;
  height: 70px;
  overflow: hidden;
  background: linear-gradient(to bottom, #fff 0, #fff 60px, transparent 60px, transparent 100%);
}

@media (min-width: 1024px) {
  .c-website-header.is-sticked {
    height: 130px;
    background: linear-gradient(to bottom, #fff 0, #fff 100px, transparent 100px, transparent 100%);
  }
}

.c-website-header.is-sticked:after {
  content: '';
  display: block;
  width: 100%;
  height: 10px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, transparent 80%);
  position: absolute;
  top: 60px;
  opacity: 1;
  transition: opacity 100ms;
}

@media (min-width: 1024px) {
  .c-website-header.is-sticked:after {
    height: 30px;
    top: 100px;
  }
}

.c-website-header.is-near-top {
  overflow: visible;
}

.c-website-header.is-near-top:after {
  opacity: 0;
  z-index: -2;
}

.c-website-header__inner {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .c-website-header__inner {
    justify-content: flex-start;
  }
}

#TVOU,
.is-near-top #TVOU {
  display: inline;
  opacity: 0;
  transition: opacity ease 150ms, transform ease 150ms 150ms;
}

#Letters_1_,
.is-near-top #Letters_1_ {
  opacity: 1;
  transition: all ease 300ms 150ms;
  transform-origin: center;
}

#Ellipse,
.is-near-top #Ellipse {
  display: inline;
  stroke: #fff;
  stroke-dasharray: 995px;
}

.is-sticked #TVOU {
  opacity: 1;
  transition: opacity ease 300ms, transform ease 300ms;
  transform: translateX(-70px);
}

@media (min-width: 1024px) {
  .is-sticked #TVOU {
    transform: translateX(0);
  }
}

.is-sticked #Letters_1_ {
  opacity: 0;
  transition: opacity ease 0s;
  transform: scale(0.92);
}

.is-near-top #TVOU {
  transform: none;
}

.c-website-header__logo {
  margin: 2.7rem 0 0 0;
  width: 25%;
  max-width: 100px;
  flex: auto 0 0;
}

@media (min-width: 768px) {
  .c-website-header__logo {
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .c-website-header__logo {
    max-width: 110px;
  }
}

@media (min-width: 1700px) {
  .c-website-header__logo {
    margin: 8.3rem 0 0;
    max-width: 125px;
  }
}

.c-website-header__logo.has-no-animation {
  transition: none;
}

.c-website-header__logo--on-layer {
  transition: transform 500ms, opacity 500ms;
  transform: translateY(0) !important;
  opacity: 0;
}

.c-website-header__logo--on-layer.is-menu-open {
  opacity: 1;
}

.c-website-header__logo.is-hidden {
  opacity: 0;
}

.c-website-header__logo.is-sticked {
  transform: translateY(-58%);
}

@media (min-width: 1024px) {
  .c-website-header__logo.is-sticked {
    transform: translateY(-40%);
  }
}

@media (min-width: 1700px) {
  .c-website-header__logo.is-sticked {
    transform: translateY(-85%);
  }
}

.c-website-header__logo--on-layer.is-sticked {
  transform: none;
}

.c-website-header__title {
  display: none;
}

@media (min-width: 768px) {
  .c-website-header__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: auto 1 1;
    margin: 0 16px 0 24px;
    height: 3.2rem;
    display: block;
  }
}

@media (min-width: 768px) {
  .c-website-header__title {
    font-size: 2.4rem;
    margin-top: 6rem;
  }
}

@media (min-width: 1024px) {
  .c-website-header__title {
    height: 4rem;
    margin-left: 50px;
    margin-top: 6.8rem;
  }
}

@media (min-width: 1700px) {
  .c-website-header__title {
    font-size: 3rem;
    margin-top: 12.8rem;
  }
}

@media (min-width: 768px) {
  .c-website-header__title.is-sticked {
    transform: translateY(-54px);
  }
}

@media (min-width: 1024px) {
  .c-website-header__title.is-sticked {
    transform: translateY(-44px);
  }
}

@media (min-width: 1700px) {
  .c-website-header__title.is-sticked {
    transform: translateY(-104px);
  }
}

.c-website-header__title-part1 {
  box-shadow: inset 0 -2px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
}

.c-website-header__title-part1:hover, .c-website-header__title-part1:focus {
  box-shadow: inset 0 -7px 0 #c1e4ea;
}

.c-website-header__title-separator {
  color: #858888;
}

.c-website-header__title-separator ~ .c-website-header__title-part1 {
  margin-right: 1.2rem;
}

.c-website-header__title-part2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #858888;
}

.c-work-page {
  font-size: 0.6rem;
  position: relative;
}

@media (min-width: 768px) {
  .c-work-page {
    font-size: 0.8rem;
    padding-bottom: 3.2rem;
  }
}

@media (min-width: 1024px) {
  .c-work-page {
    font-size: 1rem;
  }
}

.c-work {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .c-work {
    margin-top: 96px;
  }
}

@media (min-width: 1700px) {
  .c-work {
    margin-top: 150px;
  }
}

.c-work__header {
  margin-bottom: 1rem;
  width: 65%;
}

@media (min-width: 768px) {
  .c-work__header {
    margin-bottom: 2rem;
    width: 49%;
  }
}

.c-work__header-image {
  width: 100%;
}

.c-work__container {
  position: relative;
}

@keyframes normalEyePaths {
  0%, 42% {
    visibility: visible;
  }
  44%, 46% {
    visibility: hidden;
  }
  47%, 82% {
    visibility: visible;
  }
  84%, 86% {
    visibility: hidden;
  }
  87%, 88% {
    visibility: visible;
  }
  89%, 91% {
    visibility: hidden;
  }
  95%, 100% {
    visibility: visible;
  }
}

@keyframes blinkEyePaths {
  0%, 42% {
    visibility: hidden;
  }
  44%, 46% {
    visibility: visible;
  }
  47%, 82% {
    visibility: hidden;
  }
  84%, 86% {
    visibility: visible;
  }
  87%, 88% {
    visibility: hidden;
  }
  89%, 91% {
    visibility: visible;
  }
  95%, 100% {
    visibility: hidden;
  }
}

.c-work__container-binoculars-guy {
  position: absolute;
  top: 0;
  right: 7%;
  width: 16%;
  transform: translateY(-100%);
  display: none;
}

@media (min-width: 768px) {
  .c-work__container-binoculars-guy {
    display: block;
  }
}

.c-work__container-binoculars-guy svg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .c-work__container-binoculars-guy svg #left-eye path,
  .c-work__container-binoculars-guy svg #right-eye path {
    animation: normalEyePaths 8.5s infinite;
  }
}

@media (min-width: 768px) {
  .c-work__container-binoculars-guy svg path#left-eye-blink,
  .c-work__container-binoculars-guy svg path#right-eye-blink {
    visibility: hidden;
    animation: blinkEyePaths 8.5s infinite;
  }
}

.c-work__container-binoculars-guy__dummy {
  display: block;
  visibility: hidden;
  width: 100%;
}

.c-work__articles {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .c-work__articles {
    margin-bottom: auto;
  }
}

.c-workshop-teaser {
  margin-bottom: 56px;
}

@media (min-width: 768px) {
  .c-workshop-teaser {
    margin-bottom: 72px;
  }
}

@media (min-width: 1024px) {
  .c-workshop-teaser {
    margin-bottom: 96px;
  }
}

.c-workshop-teaser__summary {
  font-size: 1.8rem;
}

@media (min-width: 1024px) {
  .c-workshop-teaser__summary {
    font-size: 2.2rem;
  }
}

.c-workshop-teaser__heading {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 16px;
}

@media (min-width: 480px) {
  .c-workshop-teaser__heading {
    font-size: 2.2rem;
  }
}

@media (min-width: 768px) {
  .c-workshop-teaser__heading {
    font-size: 2.8rem;
    margin-bottom: 24px;
  }
}

@media (min-width: 1280px) {
  .c-workshop-teaser__heading {
    font-size: 3.2rem;
  }
}

@media (min-width: 1700px) {
  .c-workshop-teaser__heading {
    font-size: 3.4rem;
  }
}

.c-workshop-teaser__read-more {
  box-shadow: inset 0 -1px 0 #c1e4ea;
  color: #2c2f2e;
  padding-bottom: 2px;
  text-decoration: none;
  transition: 0.2s box-shadow ease;
  font-size: 2rem;
}

.c-workshop-teaser__read-more:hover, .c-workshop-teaser__read-more:focus {
  box-shadow: inset 0 -0.3em 0 #c1e4ea;
}

@media (min-width: 768px) {
  .c-workshop-teaser__read-more {
    font-size: 2.2rem;
  }
}

@media (min-width: 1024px) {
  .c-workshop-teaser__read-more {
    font-size: 2.4rem;
  }
}

.tippy-tooltip {
  background-color: #0086a1;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.33);
  font-size: 14px;
}

.tippy-popper[x-placement^=top] .tippy-arrow {
  border-top-color: #0086a1;
}

.u-color-white {
  color: #fff;
}

.u-color-black {
  color: #2c2f2e;
}

.u-color-lighter-grey {
  color: #e4e7e7;
}

.u-color-light-grey {
  color: #858888;
}

.u-color-medium-grey {
  color: #697170;
}

.u-color-dark-gray {
  color: #4a4e4d;
}

.u-color-darker-gray {
  color: #525857;
}

.u-color-light-blue {
  color: #87d1cb;
}

.u-color-blue {
  color: #c1e4ea;
}

.u-color-dark-blue {
  color: #0086a1;
}

.u-color-red {
  color: #ff6767;
}

.u-color-pink {
  color: #dfb5bf;
}

.u-color-green {
  color: #40bc99;
}

.u-flex {
  display: flex;
  flex-flow: row wrap;
}

.u-flex--space-between {
  justify-content: space-between;
}

.u-flex--align-bottom {
  align-items: flex-end;
}

.u-text-align-left {
  text-align: left;
}

.u-text-align-right {
  text-align: right;
}

.u-text-align-center {
  text-align: center;
}

@media (min-width: 768px) {
  .u-text-align-medium-left {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .u-text-align-medium-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .u-text-align-medium-center {
    text-align: center;
  }
}

/* Hide visually but not from screen readers */
.u-hide {
  left: -9999em !important;
  position: absolute !important;
}

.u-spacer {
  margin-bottom: 1rem;
}

.u-noscroll {
  overflow: hidden;
}

.u-fixed {
  position: fixed;
}

.u-hide\@xsmall {
  display: none;
}

@media (min-width: 375px) {
  .u-hide\@xsmall {
    display: block;
  }
}

.u-show\@xsmall {
  display: block;
}

@media (min-width: 375px) {
  .u-show\@xsmall {
    display: none;
  }
}

.u-section-margin {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .u-section-margin {
    margin-top: 40px;
  }
}

@media (min-width: 1024px) {
  .u-section-margin {
    margin-top: 72px;
  }
}
