.c-post-breadcrumb {
  margin-bottom: 24px;

  @include bp-medium {
    margin-bottom: 6rem;
  }
}

.c-post-breadcrumb--on-banner {
  color: #2c2f2e;
  left: 0;
  position: absolute;
  right: 0;
  top: 24px;
  z-index: 100;

  @include bp-medium {
    top: 40px;
  }
}

.c-post-breadcrumb__link {
  background-color: #fff;
  border-radius: 2.5px;
  font-variant: small-caps;
  color: #4a4e4d;
  text-transform: lowercase;
  font-size: 1.6rem;
  padding: 11px 16px 12px;
  margin-left: -16px;

  @include bp-full {
    padding: 14px 16px;
    font-size: 1.7rem;
  }

  .c-post-breadcrumb--on-banner & {
    background-color: rgba(#fff, 0.85);
    margin-left: 0;
  }

  &:hover,
  &:focus {
    background-color: #f7f7f7;
  }
}

.c-post-breadcrumb__text {
  display: inline-block;
  vertical-align: middle;
}

.c-post-breadcrumb__icon:before {
  content: "➔";
  display: inline-block;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-right: 6px;
  margin-top: -2px;
  position: relative;
  transform: rotateZ(180deg);
  vertical-align: middle;
}
